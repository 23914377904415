import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  SET_USER_DATA,
  SET_LOGIN_STATUS,
  PASSWORD_RESET_SEND,
  SET_EMAIL_LOGGER,
  SET_EMAIL_CHANGE_PENGING_STATUS,
  SET_AUTH_ERRORS,
  SET_USER_IS_AUTH,
  SET_REFRESHING_STATUS,
  SET_TOKEN,
  SET_EMAIL_CONFIRMED_STATUS,
  SET_LOGIN_BAD_REQUEST_ERROR,
  CREATE_STRIPE_CUSTOMER_ID,
  CREATE_STRIPE_SUBSCRIPTION,
  SET_DESKTOP_LAYOUT,
} from "../../action-types/user"

const initState = {
  user_data: {},
  isAuth: undefined,
  pending: true,
  loginIsChosen: undefined,
  pass_reset: false,
  logger_msg: "",
  email_change_pending: 0,
  refreshing: false,
  refresh_token: null,
  errors: [],
  email_confirmed: null,
  full_error: null,
}

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LOGIN_BAD_REQUEST_ERROR: {
      return {
        ...state,
        full_error: action.payload,
      }
    }
    case SET_DESKTOP_LAYOUT: {
      return {
        ...state,
        user_data: {
          ...state.user_data,
          profile: {
            ...state.user_data.profile,
            desktop_layout: action.payload,
          },
        },
      }
    }
    case SET_EMAIL_CONFIRMED_STATUS: {
      return {
        ...state,
        email_confirmed: action.payload,
      }
    }
    case SET_REFRESHING_STATUS: {
      return {
        ...state,
        refreshing: action.payload,
      }
    }
    case SET_TOKEN: {
      return {
        ...state,
        refresh_token: action.payload,
      }
    }
    case SET_USER_IS_AUTH: {
      return {
        ...state,
        isAuth: action.payload,
      }
    }
    case SET_AUTH_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      }
    }
    case SET_EMAIL_CHANGE_PENGING_STATUS: {
      return {
        ...state,
        email_change_pending: action.payload,
      }
    }
    case SET_EMAIL_LOGGER: {
      return {
        ...state,
        logger_msg: action.msg,
      }
    }
    case LOGIN_ACTION: {
      return {
        ...state,
        isAuth: true,
        pending: false,
        errors: [],
        refresh_token: action.payload,
      }
    }
    case SET_LOGIN_STATUS: {
      return {
        ...state,
        loginIsChosen: action.payload,
      }
    }
    case SET_USER_DATA: {
      return {
        ...state,
        user_data: action.payload,
        errors: [],
      }
    }
    case CREATE_STRIPE_CUSTOMER_ID: {
      return {
        ...state,
        user_data: {
          ...state.user_data,
          profile: {
            ...state.user_data.profile,
            stripe_customer_id: action.payload,
          },
        },
      }
    }
    case CREATE_STRIPE_SUBSCRIPTION: {
      return {
        ...state,
        user_data: {
          ...state.user_data,
          profile: {
            ...state.user_data.profile,
            stripe_client_secret: action.payload,
          },
        },
      }
    }
    case LOGOUT_ACTION: {
      return {
        ...state,
        user_data: {},
        isAuth: false,
        refresh_token: null,
      }
    }
    case PASSWORD_RESET_SEND: {
      return {
        ...state,
        pass_reset: true,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
