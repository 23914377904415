import { createSelector } from "reselect"
import { sortByDate } from "../../../common/helpers"

const getCommunications = (state) => state.communications

export const getCommunicationsSelector = createSelector(
  getCommunications,
  (val) => {
    return {
      drafts: sortByDate(val.draftItems, "comm_dt"),
      published: val?.items,
    }
  }
)

export const getSpecificCommunication = createSelector(
  getCommunications,
  (state) => {
    return state.specificCommunication
  }
)

export const getSpecificCommunicationContact = createSelector(
  getCommunications,
  (state) => {
    return state.specificCommunicationContact
  }
)

export const getSpecificCommunicationJob = createSelector(
  getCommunications,
  (state) => {
    return state.specificCommunicationJob
  }
)

export const getCommunicationPendingStatus = createSelector(
  getCommunications,
  (val) => val.pending
)

export const getCommunicationEditStatus = createSelector(
  getCommunications,
  (val) => val.isEdit
)
