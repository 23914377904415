import { useCallback } from "react"
import { Modal, Button } from "antd"
import { useLocation } from "react-router-dom"
import DeleteContact from "../../assets/images/delete_contact.png"
import DeleteLed from "../../assets/images/delete_lead.png"
import DeleteCompany from '../../assets/images/dalete_company_svy.svg'
import { URLS } from "../../common/urls"

export const DeleteLead = ({ visible, closeModal, onDelete, status, title = "" }) => {
  const { pathname } = useLocation()
  const renderTitle = useCallback(() => {
    return title ? `${title.charAt(0).toUpperCase()}${title.slice(1)}` : pathname === URLS.LEADS
      ? "Lead"
      : pathname === URLS.CONTACTS
        ? "Contact"
        :
        ""
  }, [pathname, title])
  const renderedTitle = renderTitle()

  return (
    <Modal
      className="delete-lead"
      open={visible}
      footer={null}
      onCancel={closeModal}
      closable={false}
    >
      <div className="df fd_col">
        <div className="df al-it_c">

          <img
            className="change-password-modal__image"
            src={renderedTitle === "Contact" ? DeleteContact : renderedTitle === "Company" ? DeleteCompany : DeleteLed}
            alt="reset password"
          />

          <div className="df fd_col ml_21">
            <span className="new-modals-title">Delete {renderedTitle}?</span>
            <span className="new-modals-subtitle">
              Are you sure you want to delete this {renderedTitle}?
            </span>
          </div>
        </div>
        <div className="delete-modal__actions mt_16">
          <Button
            className="btn-style btn-cancel"
            type="primary"
            ghost
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button type="primary" danger onClick={() => onDelete(status.id)}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  )
}
