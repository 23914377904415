
import { Button, Typography } from 'antd'
import { notifyServerWhenLogout } from '../store/actions/user'
import { useAction } from '../hooks/useAction'
import Logo from '../assets/images/logo.svg'
const { Text } = Typography

export default function NoActive() {
    const onLogout = useAction(notifyServerWhenLogout)
    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "32px" }}>
                <a href="https://savvyy.com/">
                    <img src={Logo} alt="" />
                </a>
            </div>
            <div style={{ display: "flex", justifyContent: "center", padding: '134px' }}>

                <div>
                    <Text style={{ fontWeight: 500, color: "#000000" }}>
                        This account has been deleted. Please contact
                        <a href="mailto:help@savvyy.com" style={{ color: "#009EEF" }} rel="noopener noreferrer"> help@savvyy.com </a>
                        for additional assistance.
                    </Text>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
                        <Button onClick={onLogout} type="primary">Logout</Button>
                    </div>
                </div>
            </div>
        </div >
    )
}