import { createSelector } from "reselect"

const getTodos = (state) => state.todos

export const getDraftTodoList = createSelector(getTodos, (state) => {
  return state?.draftItems || []
})

export const getTodoCurrentFilter = createSelector(getTodos, (state) => {
  return state.filter
})

export const getGroupedTodosSelector = createSelector(getTodos, (value) => {
  return value.todosGroups
})

export const getTodosSelector = createSelector(getTodos, (value) => {
  return value.todos
})

export const getTodosPendingStatus = createSelector(
  getTodos,
  (state) => state.isPending
)
