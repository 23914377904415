/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Radio
} from "antd"
import momentTZ from "moment-timezone"
import { settingsValidation, validateEmail } from "../../common/helpers"
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  UserOutlined,
} from "@ant-design/icons"
import { EmailPreferences } from "./email_preferences"
import { useDispatch, useSelector } from "react-redux"
import {
  changeUserEmail,
  setEmailChangePendingStatus,
  setEmailLogger,
  setLoginStatus,
  getMe
} from "../../store/actions/user"
import { isEqual, isEmpty } from "lodash"
import DragHomeTable from "./drag_table"
import { setDesktopLayout, editProfile } from "../../store/actions/user"
import { getIsNoLeads } from "../../store/selectors/user/getUserData"

const { Title } = Typography

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 9 },
}
const tailLayout = {
  wrapperCol: { span: 16 },
}
export const VerifyPageForm = ({
  profile = {},
  onSubmit,
  changeEmailStatus,
  loginIsChousen,
  handlePasswordReset,
  handleDoneTyping
}) => {
  const emailChangeStatus = useSelector(
    (state) => state.user.email_change_pending
  )
  const timeZonesList = momentTZ.tz.names()
  const [showWarning, setShowWarning] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [showNameWarning, setShowNameWarning] = useState(false)
  const emailLogger = useSelector((state) => state.user.logger_msg)
  const isLeads = useSelector(getIsNoLeads)
  const dispatch = useDispatch()
  const [loginWarning, setLoginWarning] = useState(false)
  const [initValue, setInitValue] = useState({
    first_name: "",
    email: "",
    username: "",
    tz: profile.profile?.tz,
    diary_sw: profile.profile?.diary_sw,
    summary_sw: profile.profile?.summary_sw,
    reminder_sw: profile.profile?.reminder_sw,
    subscription_type: profile.profile?.subscription_type,
    desktop_layout: profile.profile?.desktop_layout,
    ...profile,
  })
  let typingTimer
  const doneTypingInterval = 500
  const validateResult = () => {
    return settingsValidation.isValid(initValue)
  }
  const handleSubmit = async () => {
    const result = await validateResult()
    if (!result) {
      Object.keys(initValue).forEach((key) => {
        onSetValidationErrors(key, initValue[key])
      })
      return
    }
    const {
      username,
      email,
      first_name,
      tz,
      diary_sw,
      reminder_sw,
      summary_sw,
      subscription_type,
    } = initValue
    const data = {
      username,
      email,
      first_name,
      profile: {
        diary_sw,
        reminder_sw,
        summary_sw,
        tz,
        subscription_type,
      },
    }

    if (data.username.trim() === profile.username.trim()) {
      delete data.username
    }
    onSubmit(
      data,
      Boolean(emailIsValid && data.email?.trim() !== profile.email)
    )
  }
  const onSetValidationErrors = (type, value) => {
    switch (type) {
      case "first_name": {
        if (value?.trim()) {
          showNameWarning && setShowNameWarning(false)
        } else {
          !showNameWarning && setShowNameWarning(true)
        }
        break
      }
      case "email": {
        dispatch(setEmailLogger(""))
        if (validateEmail(value)) {
          !emailIsValid && setEmailIsValid(true)
        } else {
          setEmailIsValid(false)
        }
        break
      }
      case "tz": {
        const tz = timeZonesList?.find((item) => {
          return item?.trim() === value?.trim()
        })
        if (tz) {
          showWarning && setShowWarning(false)
        } else {
          !showWarning && setShowWarning(true)
        }
        break
      }
      case "username": {
        const isEmptyValue = !Boolean(value.trim())
        loginIsChousen && dispatch(setLoginStatus(false))
        setLoginWarning(isEmptyValue)
        break
      }
      default:
        break
    }
  }
  const onChangeValue = async (e, key) => {
    const name = key ? key : e.target.dataset.name
    const value = name === "tz" ? e : e.target.value

    onSetValidationErrors(name, value)
    if (name === "email") {
      changeEmailStatus(true)
    }
    let data = { ...initValue }
    data[name] = value
    setInitValue(data)
  }
  const options = timeZonesList.map((item) => ({
    label: item,
    value: item,
  }))
  const handleKeyDown = () => {
    clearTimeout(typingTimer)
  }
  const handleKeyUp = (type) => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(() => doneTyping(type), doneTypingInterval)
  }
  const doneTyping = (type) => {
    if (type === "username") {
      handleDoneTyping(initValue.username)
    } else if (
      type === "email" &&
      Boolean(emailIsValid) &&
      !isEqual(initValue.email, profile.email)
    ) {
      emailIsValid && dispatch(setEmailChangePendingStatus(1))
      dispatch(changeUserEmail(initValue.email))
    }
  }
  useEffect(() => {
    if (!isEmpty(profile)) {
      settingsValidation
        .validate(
          { ...profile, tz: profile.profile?.tz },
          { abortEarly: false }
        )
        .catch((err) => {
          err.inner.forEach((item) => {
            onSetValidationErrors(item.path, "")
          })
        })
    }
    return () => {
      dispatch(setEmailChangePendingStatus(0))
    }
  }, [profile])
  useEffect(() => { dispatch(getMe()) }, [])
  return (
    <div style={{ position: "relative" }}>
      <Form {...layout} name="basic">
        {/*<ProjectMode/>*/}

        <Title level={2}>User Info</Title>

        <Form.Item label="Name">
          <Row gutter={16}>
            <Col span={21}>
              <Input
                data-name={"first_name"}
                placeholder="Please enter your name"
                suffix={<UserOutlined />}
                value={initValue.first_name}
                onChange={onChangeValue}
              />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handlePasswordReset}
              >
                Change Password
              </Button>
            </Col>
            {!initValue.first_name && showNameWarning && (
              <span style={{ color: "red", marginLeft: ".4rem" }}>
                Please enter your name
              </span>
            )}
          </Row>
        </Form.Item>

        <Form.Item label="Email">
          <Row gutter={16} style={{ alignItems: "center" }}>
            <Col span={21}>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <Input
                  data-name="email"
                  onKeyDown={handleKeyDown}
                  onKeyUp={() => handleKeyUp("email")}
                  value={initValue.email}
                  onChange={onChangeValue}
                />

                {emailChangeStatus === 2 ? (
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "20px", marginLeft: "1rem" }}
                  />
                ) : emailChangeStatus === 1 ? (
                  <Spin style={{ marginLeft: "1rem" }} />
                ) : emailChangeStatus === 3 ? (
                  <CloseCircleTwoTone
                    twoToneColor="red"
                    style={{
                      fontSize: "20px",
                      marginLeft: "1rem",
                    }}
                  />
                ) : null}
              </div>
              {!emailIsValid && (
                <span style={{ color: "red", marginTop: "1rem" }}>
                  Please enter a valid email
                </span>
              )}

              {emailLogger?.trim() && (
                <span style={{ color: "red", marginTop: "1rem" }}>
                  {emailLogger}
                </span>
              )}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Login">
          <Row gutter={16}>
            <Col span={21}>
              <Input
                style={
                  loginIsChousen
                    ? { borderColor: "#FF4D4F" }
                    : { backgroundColor: "#fff" }
                }
                rules={[
                  { required: true, message: "This login is already taken" },
                ]}
                data-name="username"
                defaultValue={profile.username}
                onKeyDown={handleKeyDown}
                onKeyUp={() => handleKeyUp("username")}
                value={initValue.username}
                onChange={onChangeValue}
                suffix="@savvyy.com"
              />
              {!loginWarning && loginIsChousen && (
                <span style={{ color: "#FF4D4F" }}>
                  This login is already taken
                </span>
              )}
              {loginWarning && (
                <span style={{ color: "#FF4D4F" }}>Please choose a login</span>
              )}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name="tz" label="Time Zone">
          <Row gutter={16}>
            <Col span={21}>
              <AutoComplete
                options={options}
                placeholder="Please enter your region to select a time zone"
                defaultValue={initValue.tz}
                onChange={(val) => onChangeValue(val, "tz")}
                onSelect={(val) => {
                  setShowWarning(false)
                  onChangeValue(val, "tz")
                }}
                filterOption={(inputValue, option) => {
                  return (
                    option?.label
                      ?.toUpperCase()
                      ?.indexOf(inputValue?.toUpperCase()) !== -1
                  )
                }}
              />
              {!initValue.tz && showWarning && (
                <span style={{ color: "red" }}>
                  Please enter your region to select a time zone
                </span>
              )}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name="tz" label="Leads">
          <Row gutter={16}>
            <Col span={21}>
              <Radio.Group
                onChange={(e) => {
                  const { value } = e.target
                  const desktop_layout = value ? ['leads'] : ['contacts']
                  dispatch(editProfile({ profile: { desktop_layout, leads_sw: value } }))
                  dispatch(setDesktopLayout(desktop_layout))
                }}
                value={isLeads}
              >
                <Radio value={true}>YES</Radio>
                <Radio value={false}>NO</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Form.Item>





        <EmailPreferences initValue={initValue} setInitValue={setInitValue} />
        <Title level={2}> Display Preferences</Title>
        <Col span={21}>
          <DragHomeTable sub_type={profile.profile?.subscription_type} />
        </Col>
        <Form.Item {...tailLayout}>
          <Button
            onClick={handleSubmit}
            type="primary"
            className="profile_settings-btn"
          >
            Done
          </Button>
        </Form.Item>
      </Form>

    </div>
  )
}
