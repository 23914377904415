import { useCallback, useState, useRef, useEffect, Fragment, memo } from "react"
import { Link } from "react-router-dom"
import { Popover, message, Table } from "antd"
import moment from "moment"
import * as XLSX from "xlsx"
import { useColors } from "./useColors"
import { useAction } from "./useAction"
import {
  LinkedinFilled,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { DATE_FORMAT } from "../common/config"
import { JOB_DISCARDED, CRM_MODE } from "../common/constants"
import { TagsList } from "../components/tags/TagsList"
import { getContentLastContact } from "../common/helpers"
import { deleteContactTH } from "../store/actions/contacts/delete-contact"
import TitlePopup from "../components/TitlePopup"
import JobsStatusSwitcher from "../components/leads/apply-tab"
import { updateJobTH } from "../store/actions/jobs/update-job"
import { deleteJobTH } from "../store/actions/jobs/delete-job"
import { removeCompany } from "../store/actions/companies/remove-company"
import { Notes } from "../components/notes/Notes"
import { DeleteLead } from "../components"

const settings = {
  bottom: "bottomRight",
  hasData: true,
  pagination: false,
  showHeader: true,
  size: "small",
  tableLayout: "fixed",
  title: undefined,
  top: "none",
}

const EntityTable = memo(
  ({
    tableContainerRef,
    columns,
    item,
    title,
    openModal,
    openDeleteModal,
    setOpenDeleteModal,
    submittedEntityId,
    onDelete,
  }) => {
    return (
      <div ref={tableContainerRef} className="table-animation">
        <Table
          {...settings}
          scroll={{ y: "75vh", scrollToFirstRowOnChange: false }}
          rowKey={(row) => row.id}
          columns={columns}
          dataSource={item}
          onRow={(record) => {
            return {
              onClick: (event) => {
                openModal(title, record)
              },
            }
          }}
        />
        {openDeleteModal && (
          <DeleteLead
            visible={openDeleteModal}
            closeModal={() => setOpenDeleteModal(false)}
            status={submittedEntityId}
            onDelete={onDelete}
            title={title}
          />
        )}
      </div>
    )
  }
)

export const useSearchTable = ({
  title,
  item,
  openModal,
  leadInfo,
  setIsExpandedContainer,
  subType,
}) => {
  const { allColors } = useColors()
  const dataContactColumns = [
    {
      title: "Name",
      key: "first_name",
      width: "16.6%",
      className: "scroll-hidden-column",
      render: ({ first_name, lin_url }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{first_name?.slice(0, 30)}</span>
            {lin_url && (
              <a
                href={
                  lin_url?.includes("http") ? lin_url : "https://" + lin_url
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinFilled style={{ color: "#009EEF" }} />
              </a>
            )}
          </div>
        )
      },
      sorter: (a, b) => {
        return a?.first_name
          ?.toLowerCase()
          .localeCompare(b?.first_name.toLowerCase())
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company_name",
      width: "13%",
      className: "scroll-hidden-column",
      render: (company) => <span>{company?.name}</span>,
      sorter: (a, b) => {
        return a?.company?.name
          ?.toLowerCase()
          .localeCompare(b?.company?.name?.toLowerCase())
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      key: "phone",
      width: "10%",
      className: "scroll-hidden-column",
      render: ({ phone, personal_mobile }) => {
        const myPhone = phone || personal_mobile
        return <span>{myPhone && myPhone}</span>
      },
    },
    {
      title: "Email",
      key: "email",
      width: "10%",
      className: "scroll-hidden-column",
      render: ({ email, personal_email }) => {
        const myEmail = email || personal_email
        return <span>{myEmail && myEmail}</span>
      },
      sorter: (a, b) => {
        return a?.email?.toLowerCase().localeCompare(b?.email?.toLowerCase())
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Location",
      key: "city",
      dataIndex: "city",
      maxWidth: "24%",
      render: (city) => {
        return <span>{city}</span>
      },
      sorter: (a, b) => {
        return a?.city?.toLowerCase().localeCompare(b?.city?.toLowerCase())
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Date Added",
      key: "create_dt",
      dataIndex: "create_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.create_dt) - new Date(a.create_dt)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Last Contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "10%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7)
        const days = totalDays % 7
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: (a, b) => {
        return b?.seasoning - a.seasoning
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => <TagsList tags={tags} allColors={allColors} />,
    },
    {
      title: "Actions",
      width: "7%",
      render: (_, c) => {
        return (
          <>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation()
                openModal(title, c)
              }}
              style={{ marginRight: "1rem" }}
            />
            <DeleteOutlined
              style={{ color: "#f25041" }}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteContact(c.id)
              }}
            />
          </>
        )
      },
    },
  ]
  const dataJobsColumns = [
    {
      title: leadInfo.name,
      key: "job",
      width: "40%",
      render: ({ company, title, url }) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TitlePopup
              title={`${company?.name ? company.name : ""}${
                company?.name && title ? ", " : ""
              } ${title ? title : ""}`}
              maxLength={80}
            />
            {url && (
              <a
                href={url?.includes("http") ? url : "https://" + url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkOutlined
                  width={20}
                  style={{
                    marginTop: "2px",
                    color: "#009EEF",
                    fontSize: "17px",
                    cursor: "pointer",
                  }}
                />
              </a>
            )}
          </div>
        )
      },
      sorter: (a, b) => {
        return a?.company?.name
          ?.toLowerCase()
          .localeCompare(b?.company?.name?.toLowerCase())
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "21.2%",
      sorter: (a, b) => {
        return a?.location
          ?.toLowerCase()
          .localeCompare(b?.location?.toLowerCase())
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Added",
      dataIndex: "create_dt",
      key: "create_dt",
      width: "8.2%",
      render: (date) => {
        return <span>{moment(new Date(date)).format(DATE_FORMAT)}</span>
      },
      sorter: (val) => {
        return -new Date(val.create_dt).getTime()
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Last contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "10%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7)
        const days = totalDays % 7
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: (a, b) => {
        return a?.seasoning - b?.seasoning
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
      sorter: (a, b) => {
        return a?.tags?.length - b?.tags?.length
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status_id",
      width: "10%",
      render: (status, lead) => {
        return <JobsStatusSwitcher job={lead} />
      },
    },
    {
      title: "Actions",
      width: "7%",
      render: (record, row) => {
        return (
          <div>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation()
                openModal(title, row)
              }}
              style={{ marginRight: "1rem" }}
            />
            <Popover
              content={
                row.status_id !== JOB_DISCARDED
                  ? `When pressed, the ${
                      subType === CRM_MODE
                        ? "lead will be completed"
                        : "job will be discarded"
                    }`
                  : "When pressed, the job will be deleted"
              }
            >
              {row.status_id === JOB_DISCARDED ? (
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleJobDelete(row)
                  }}
                  style={{ color: "#F25041" }}
                />
              ) : subType === "CRM" ? (
                <CheckOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleJobDelete(row)
                  }}
                />
              ) : (
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleJobDelete(row)
                  }}
                  style={{ color: "#F25041" }}
                />
              )}
            </Popover>
          </div>
        )
      },
    },
  ]
  const dataCompanyColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "16.8%",
      sorter: (a, b) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "state",
      width: "16.8%",
      sorter: (a, b) =>
        a?.state?.toLowerCase().localeCompare(b?.state?.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contacts",
      dataIndex: "contact_count",
      key: "contacts",
      width: "6.4%",
      sorter: (a, b) => a?.contact_count - b?.contact_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Notes",
      dataIndex: "note_count",
      key: "attachments",
      width: "6.4%",
      sorter: (a, b) => a?.note_count - b?.note_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },
    {
      title: "Added",
      key: "create_dt",
      dataIndex: "create_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.create_dt) - new Date(a.create_dt)
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Updated",
      key: "last_edit_dt",
      dataIndex: "last_edit_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.last_edit_dt) - new Date(a.last_edit_dt)
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      width: "7%",
      render: (_, company) => {
        return (
          <Fragment>
            <EditOutlined
              style={{ marginRight: "1rem" }}
              onClick={(e) => {
                e.stopPropagation()
                openModal(title, company)
              }}
            />
            <DeleteOutlined
              data-company={company.id}
              style={{ color: "#F25041" }}
              onClick={handleOpenCompanyDeleteModal}
            />
          </Fragment>
        )
      },
    },
  ]
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [submittedEntityId, setSubmittedEntityId] = useState(null)
  const tableContainerRef = useRef(null)
  const blockContainerRef = useRef(null)
  const deleteContactAction = useAction(deleteContactTH)
  const updateJobAction = useAction(updateJobTH)
  const deleteJobAction = useAction(deleteJobTH)
  const deleteCompanyAction = useAction(removeCompany)
  const [messageApi, contextHolder] = message.useMessage()

  const infoMessage = (data) => {
    return messageApi.open({
      type: "info",
      content: (
        <div>
          Job has been discarded.{" "}
          <Link to="#" onClick={() => onRevertLead(data)}>
            Undo
          </Link>
        </div>
      ),
      duration: 5,
      key: data.id,
    })
  }

  const handleOpenCompanyDeleteModal = (e) => {
    e.stopPropagation()
    setSubmittedEntityId(e.currentTarget?.dataset?.company)
    setOpenDeleteModal(true)
  }

  const toggleJobDelete = async ({ id, status_id }) => {
    if (status_id === 3) {
      setSubmittedEntityId(id)
      setOpenDeleteModal(true)
    } else {
      await updateJobAction({ status_id: 3, id })
      infoMessage({ id, status_id })
    }
  }

  const onRevertLead = async ({ id, status_id }) => {
    await updateJobAction({ id, status_id })
    messageApi.destroy(id)
  }

  const handleDeleteContact = (id) => {
    setSubmittedEntityId(id)
    setOpenDeleteModal(true)
  }
  const deleteEntity = {
    job: deleteJobAction,
    contact: deleteContactAction,
    company: deleteCompanyAction,
  }
  const onDelete = async () => {
    await deleteEntity[title](submittedEntityId)
    setOpenDeleteModal(false)
  }

  const onClickExpand = useCallback(() => {
    const result = !isExpanded
    if (result) {
      tableContainerRef.current?.classList.remove("collapsing")
      blockContainerRef.current?.classList.remove("collapsing")
    } else {
      tableContainerRef.current?.classList.add("collapsing")
      blockContainerRef.current?.classList.add("collapsing")
    }
    setTimeout(() => {
      setIsExpanded(result)
      setIsExpandedContainer((prev) => !prev)
    }, 200)
  }, [isExpanded, tableContainerRef, blockContainerRef, setIsExpandedContainer])
  const prepareDownloadContacts = (i) => ({
    Name: i.first_name,
    Title: i.title,
    Company: i.company.name,
    Street: i.street_1,
    "City/Sate/Zip": i.city,
    "Work Phone": i.phone,
    Mobile: i.mobile,
    "Personal Mobile": i.personal_mobile,
    Email: i.email,
    "Personal Email": i.personal_email,
    "Last Contact": i.last_contact
      ? moment(i.last_contact).format("YYYY-MM-DD")
      : "",
    Birthday: i.birthday,
    Notes: i.notes,
    Added: moment(i.create_dt).format("YYYY-MM-DD"),
    "Last Edit": moment(i.last_edit_dt).format("YYYY-MM-DD"),
    Tags: i.tags?.map((t) => t.tag).join(", "),
  })

  const jobStatus = {
    1: "New",
    2: "Applied",
    3: "Discarded",
  }
  const getLastContact = (totalDays) => {
    const weeks = Math.floor(totalDays / 7)
    const days = totalDays % 7
    return getContentLastContact(weeks, days)
  }

  const prepareDownloadJobs = (i) => ({
    Title: i.title,
    Description: i.description,
    Contact: i.contact?.first_name,
    Company: i.company?.name,
    Notes: i.notes,
    Status: jobStatus[i.status_id],
    "Date Added": moment(i.create_dt).format("YYYY-MM-DD"),
    "Last Contact": getLastContact(i.seasoning),
    Tags: i.tags?.map((t) => t.tag).join(", "),
  })

  const prepareDownloadCompany = (i) => ({
    Name: i.name,
    State: i.state,
    "City/Sate/Zip": i.city,
    "Contact Count": i.contact_count,
    "Note count": i.note_count,
    Tags: i.tags?.map((t) => t.tag).join(", "),
    Added: moment(i.create_dt).format("YYYY-MM-DD"),
  })

  const prepareDownloadNote = (i) => ({
    Title: i.title,
    Text: i.body_txt,
    Tags: i.tags?.map((t) => t.tag).join(", "),
    Added: moment(i.create_dt).format("YYYY-MM-DD"),
  })

  const prepareStrategy = {
    job: prepareDownloadJobs,
    contact: prepareDownloadContacts,
    company: prepareDownloadCompany,
    note: prepareDownloadNote,
  }

  const downloadExcel = () => {
    const data = item.map(prepareStrategy[title])
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      title === "job" ? leadInfo.name : title
    )
    XLSX.writeFile(workbook, "data.xlsx")
  }

  const dataSourceColumns = {
    job: dataJobsColumns,
    contact: dataContactColumns,
    company: dataCompanyColumns,
  }

  const expandedComponents = {
    job: (
      <EntityTable
        tableContainerRef={tableContainerRef}
        columns={dataSourceColumns.job}
        item={item}
        title={leadInfo.name}
        openModal={openModal}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    ),
    contact: (
      <EntityTable
        tableContainerRef={tableContainerRef}
        columns={dataSourceColumns.contact}
        item={item}
        title={title}
        openModal={openModal}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    ),
    company: (
      <EntityTable
        tableContainerRef={tableContainerRef}
        columns={dataSourceColumns.company}
        item={item}
        title={title}
        openModal={openModal}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onDelete={onDelete}
      />
    ),
    note: (
      <div
        ref={tableContainerRef}
        className="table-animation"
        style={{ padding: 20 }}
      >
        <Notes notes={item} allColors={allColors} isCreate={false} />
      </div>
    ),
  }

  useEffect(() => {
    if (isExpanded && item?.length === 0) {
      setIsExpanded(false)
      setIsExpandedContainer(false)
    }
  }, [item?.length, isExpanded, setIsExpandedContainer])
  return {
    isExpanded,
    downloadExcel,
    onClickExpand,
    ExpandedComponent: expandedComponents[title],
    blockContainerRef,
    isCanExpand: title in expandedComponents && item.length > 0,
    contextHolder,
  }
}
