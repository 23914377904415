import React, { useEffect } from "react"
import { Button, Input, Modal, Typography, Spin, Popconfirm } from "antd"
import { ExpandAltOutlined } from "@ant-design/icons"
import "react-quill/dist/quill.snow.css"
import { QuilEditor } from "../editor/quil-editor"
import withMemo from "../../store/HOC/with_memo"
import AddTag from "../add_tag"
import { useNoteEditor } from "../../hooks/useNoteEditor"
import { editNote } from "../../store/actions/notes/edit_note"
import { AttsBookmarksTab } from "./atts-bookmarks-tab"
import { DATE_FORMAT } from "../../common/config"
import moment from "moment"
import { AttachmentsLinksCard } from "./AttachmentsLinksCard"
import { isEmpty } from "lodash"
import ModalCloseIcon from "../modals/modal-close-icon"
import Spinner from "../loaders/spinner"
import { FeaturesTypes } from "../../common/constants"
import { ReactComponent as CollapseExpandIcon } from '../../assets/images/collapse-svgrepo-com (1).svg'

const { Text } = Typography

export const NoteEditor = withMemo(
  ({
    visible,
    onClose,
    handleAddNote,
    handleDeleteNote,
    handleEditNote,
    onSaveCallback = false,
    title,
    is_diary,
    style,
    saveCompany
  }) => {
    const {
      editorRef,
      addTagStep,
      inputRef,
      specificNote,
      data,
      onNoteChange,
      // handleSubmit,
      setData,
      setAddTagStep,
      urlTitle,
      handleChangeUrl,
      url,
      handlePressEnterOnLink,
      popoverIsOpen,
      setPopoverIsOpen,
      file_is_pending,
      link_is_pending,
      togglePopover,
      handleSave,
      handleRemoveChip,
      handleDownloadFile,
      chips,
      links,
      atts,
      created,
      setCreated,
      createNoteBefore,
      onCloseEditor,
      showEditor,
      loadingQuill,
      showQuillChild,
      setShowQuillChild,
      handFocusQuill,
      handleTitleChange,
      titleRef
    } = useNoteEditor(handleAddNote, handleEditNote, title, onClose, is_diary, onSaveCallback, saveCompany)

    const [inputVal, setInputVal] = React.useState(data.title)
    const [isRequest, setIsRequest] = React.useState(false)
    const [modalWidth, setModalWidth] = React.useState(1000)

    const handleChange = React.useCallback(
      (e) => {
        setInputVal(e.target.value)
        setData({ ...data, title: e.target.value })
        handleTitleChange({ title: e.target.value, workspace_id: data.workspace_id })
      },
      [data, handleTitleChange, setData]
    )
    const onPressLinkEnter = async () => {
      try {
        setIsRequest(true)
        await handlePressEnterOnLink()
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => setIsRequest(false), 500)
      }
    }
    const handleExpandModal = React.useCallback(() => {
      setShowQuillChild(true)
      setTimeout(() => {
        setModalWidth(modalWidth === 1000 ? "90vw" : 1000)
        document.querySelector('.note-container-quill-editor .ql-container').classList.toggle('expanded')
      }, 100)
      setTimeout(() => {
        editorRef.current.focus()
      }, 500)
    }, [editorRef, modalWidth, setShowQuillChild])
    useEffect(() => {
      if (titleRef?.current && !inputVal && !showQuillChild) {
        setTimeout(() => titleRef?.current?.focus(), 900)
      }
    }, [inputVal, showQuillChild, titleRef])

    return (
      <Modal
        className={`${is_diary ? "docs_modal diary_editor" : "docs_modal note_editor"}`}
        closeIcon={<ModalCloseIcon />}
        maskClosable={!isRequest}
        keyboard={!isRequest && !Boolean(addTagStep)}
        closable={!isRequest}
        style={{ ...style, transition: 'width 0.3s ease-in-out' }}
        title={
          !is_diary ? (
            <>
              <div
                style={{
                  marginRight: "24px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    marginRight: "21px",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Title"
                    style={{
                      height: "32px",
                    }}
                    autoFocus
                    ref={titleRef}
                    defaultValue={inputVal}
                    value={inputVal}
                    onChange={handleChange}
                  />
                </div>
                <AttsBookmarksTab
                  created={created}
                  setCreated={setCreated}
                  createNoteBefore={createNoteBefore}
                  handleRemoveChip={handleRemoveChip}
                  handleDownloadFile={handleDownloadFile}
                  urlTitle={urlTitle}
                  handleSave={handleSave}
                  handFocusQuill={handFocusQuill}
                  handleChangeUrl={handleChangeUrl}
                  handlePressEnter={onPressLinkEnter}
                  url={url}
                  popoverIsOpen={popoverIsOpen}
                  setPopoverIsOpen={setPopoverIsOpen}
                  link_is_pending={link_is_pending}
                  togglePopover={togglePopover}
                  file_is_pending={file_is_pending}
                  chips={chips}
                />
                <div onClick={handleExpandModal} className="expand-icon">
                  {modalWidth !== 1000 ? <CollapseExpandIcon /> : <ExpandAltOutlined style={{ fontSize: 20 }} />}

                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <span>
                  Diary: {moment(specificNote.create_dt).format(DATE_FORMAT)}
                </span>
                <div onClick={handleExpandModal} className="expand-icon" style={{ padding: "2px", position: "absolute", top: "-4px", right: "25px" }}>
                  {modalWidth !== 1000 ? <CollapseExpandIcon /> : <ExpandAltOutlined style={{ fontSize: 18 }} />}
                </div>
              </div>
            </>
          )
        }
        centered
        open={visible}
        onCancel={onCloseEditor}
        footer={
          !isEmpty(links) || !isEmpty(atts) ? (
            <AttachmentsLinksCard
              links={links}
              atts={atts}
              handleRemoveChip={handleRemoveChip}
            />
          ) : null
        }
        width={modalWidth}
      >
        {!is_diary && (
          <div className="docs-modal-tags">
            <AddTag
              item={specificNote}
              addTagStep={addTagStep}
              inputRef={inputRef}
              setAddTagStep={setAddTagStep}
              update={editNote}
              type={FeaturesTypes.NOTE}
            />
          </div>
        )}
        <div className={`note-container-quill-editor ${modalWidth !== 1000 ? "note-quill-editor" : ""}`} style={{ position: "relative" }} >
          <div style={{ position: "absolute", top: modalWidth !== 1000 ? "1%" : '2.5%', right: '5%' }}>
            {loadingQuill && <Spinner />}
          </div>
          {showEditor &&
            <QuilEditor
              editorRef={editorRef}
              content={data?.body_editor?.ops ? data.body_editor : data?.body_html}
              onChange={onNoteChange}
              is_diary={is_diary}
              focus={true}
              showChild={showQuillChild}
              setShowChild={setShowQuillChild}
            />
          }
        </div>


        <div
          style={{
            marginTop: "12px",
            marginBottom: "4px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {specificNote.id && handleDeleteNote && (
            <Popconfirm
              placement="topRight"
              className="delete_file"
              title="Delete note?  There is no undo."
              onConfirm={() => handleDeleteNote(specificNote.id)}
              okText="Delete"
              cancelText="Cancel"
            >

              <Button
                style={{
                  borderRadius: "6px",
                  background: "#F25041",
                  marginRight: "auto",
                  color: "white",
                }}
                type="danger"
                disabled={isRequest}
              >
                Delete
              </Button>
            </Popconfirm>

          )}

          {specificNote.id && !is_diary && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "12px",
              }}
            >
              <Text style={{ lineHeight: "1.2", color: "rgba(0, 0, 0, 0.6)" }}>
                Added: {moment(specificNote.create_dt).format(DATE_FORMAT)}
              </Text>
              {specificNote.last_edit_dt && (
                <Text
                  style={{ lineHeight: "1.2", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Last edit:{" "}
                  {moment(specificNote.last_edit_dt).format(DATE_FORMAT)}
                </Text>
              )}
            </div>
          )}
        </div>
        {
          isRequest && (
            <div style={{ position: "fixed", left: "48%", top: "45%" }}>
              <Spin />
            </div>
          )
        }

      </Modal >
    )
  }
)
