import React, { useCallback, useEffect, useState } from "react"
import { Badge, Divider, Tooltip } from "antd"
import withMemo from "../store/HOC/with_memo"
import { renderTitleByType, sortByDate } from "../common/helpers"
import "../styles/search.css"
import { KeyDate } from "./keyDate"
import { ExpandAltOutlined, DownloadOutlined, CloseOutlined } from "@ant-design/icons"
import { useSearchTable } from "../hooks/useSearchTable"
import { useAction } from '../hooks/useAction'
import { getJobsSelector, getContactsSelector } from '../store/selectors'
import { useSelector } from 'react-redux'
import { fetchJobByIdIfNeeded } from '../store/actions/jobs/get-jobs'
import { fetchContactByIdIfNeeded } from '../store/actions/contacts/get-contacts-list'



const ConvoInformation = ({ convo, jobs, contacts }) => {
  const [contact, setContact] = useState(null)
  const [job, setJob] = useState(null)
  useEffect(() => {
    if (convo.contact) {
      contacts.forEach((value) => {
        if (value?.id === convo?.contact) {
          setContact(value)
        }
      })
    }
    if (convo.job) {
      jobs.forEach((value) => {
        if (value.id === convo.job) {
          setJob(value)
        }
      })
    }
  }, [convo, jobs, contacts])
  return (
    <div style={{ marginBottom: 10 }}>
      {job?.title && convo.job && (
        <span>
          {job?.title || ""}
          {job?.company?.name && !contact?.first_name ? (", " + job?.company?.name) : ""}
        </span>
      )}
      {job?.title && contact?.first_name && <span>{" - "}</span>}
      {contact?.first_name && convo.contact && (
        <span>
          {contact?.first_name || ""}
          {contact?.company?.name ? (", " + contact?.company?.name) : ""}
        </span>
      )}

    </div>
  )
}

const SearchResultItem = ({
  title,
  item,
  openModal,
  leadInfo,
  setIsExpandedContainer,
  isExpandedContainer,
  subType
}) => {
  const renderTitle = useCallback((title) => {
    if (typeof title !== "string") return ""
    if (title === 'job') return leadInfo.name
    return title.charAt(0).toUpperCase() + title.slice(1)
  }, [leadInfo.name])
  const jobs = useSelector(getJobsSelector)
  const contacts = useSelector(getContactsSelector)
  const getContactAction = useAction(fetchContactByIdIfNeeded)
  const getJobAction = useAction(fetchJobByIdIfNeeded)
  useEffect(() => {
    if (title === 'communication' && item.length > 0) {
      Promise.all(item.filter(i => !!i.contact).map(i => getContactAction(i.contact)))
      Promise.all(item.filter(i => !!i.job).map(i => getJobAction(i.job)))
    }

  }, [getContactAction, getJobAction, item, title]);

  const {
    isExpanded,
    downloadExcel,
    onClickExpand,
    ExpandedComponent,
    blockContainerRef,
    isCanExpand,
    contextHolder
  } = useSearchTable({ title, item, openModal, leadInfo, setIsExpandedContainer, subType })

  return (
    <div
      ref={blockContainerRef}
      className={`table-search-container ${isExpanded ? 'expanded' : ''} ${!isExpanded && isExpandedContainer ? 'collapse' : ''}`}>
      <div className={`default ${title} result_item`} style={isExpanded ? { maxWidth: '100%' } : {}}>
        <div className="space-between d-flex align-center">
          <div style={{ display: 'flex' }}>
            <span style={{ fontWeight: "500", marginRight: 15 }}>{renderTitle(title)}</span>

          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isCanExpand && !isExpanded &&
              <div onClick={onClickExpand}
                className="expand-icon-search">
                <ExpandAltOutlined />
              </div>}
            {isCanExpand && isExpanded &&
              <div className="download-icon-search" style={{ marginRight: 15 }}>
                <Tooltip title="Download .xlsx">
                  <DownloadOutlined style={{ fontSize: 18 }} onClick={downloadExcel} />
                </Tooltip>
              </div>
            }
            <Badge
              className={`result_badge ${title}_badge`}
              count={
                Array.isArray(item) && item.length > 0 ? item.length : "Not found"
              }
            />

            {isCanExpand && isExpanded &&
              <div onClick={onClickExpand}
                className="expand-icon-search">
                <CloseOutlined />
              </div>}
          </div>

        </div>
      </div>
      {isExpanded ?
        <>
          {contextHolder}
          {ExpandedComponent}
        </>
        :
        <div className="result-column" style={{ overflow: 'auto' }}>
          {sortByDate(item).map((val) => {
            return (
              <div
                key={val.id}
                className="search-column-item"
                onClick={() => openModal(title, val)}
              >
                <div
                  className="search-column-item-x"
                  style={{
                    marginLeft: "12px",
                    marginRight: "12px",
                    padding: "6px 0",
                  }}
                >
                  <span>
                    {title === 'communication' && <ConvoInformation convo={val} jobs={jobs} contacts={contacts} />}
                    <div className="content-text" style={{ marginBottom: 10 }}>
                      {renderTitleByType(val, title).trim()}
                      {renderTitleByType().trim() && "-"}
                    </div>
                    <KeyDate title={"Added"} date={val.create_dt} />
                  </span>
                </div>
                <Divider style={{ margin: "0" }} />
              </div>
            )
          })}
        </div>}


    </div>
  )
}
export default withMemo(SearchResultItem)
