import React, { useCallback, useRef, useState, useEffect } from "react"
import { Checkbox, DatePicker, Input, List, Popover, Popconfirm } from "antd"
import moment from "moment"
import { TodoItem } from "./TodoItem"
import { TODO_DONE_STATUS, TODO_NOT_DONE_STATUS } from "../common/constants"
import { deleteTodo } from "../store/actions/todos/delete"
import { updateTodoTh } from "../store/actions/todos/update-todo"
import { DeleteOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { removeDraftTodo, updateDraftTodo } from "../store/actions/todos/draft_todos"
import { ReactComponent as JobIcon } from "../assets/images/job_icon.svg"
import { UserOutlined } from "@ant-design/icons"
import TodoIconPop from "./todo/todoIconPop"

export const DraggableTodoItem = ({
  editableTodo,
  setEditableTodo,
  todo,
  setIsEdit,
  isEdit,
  inputRef,
  container,
  setPickerIsOpenToParent,
  onCloseParentModal,
  handleSaveTodo,
  handleOpenModal,
  isShow
}) => {
  const dispatch = useDispatch()
  const [dateIsEdit, setDateIsEdit] = useState(false)
  const [description, setDescription] = useState("")
  const dateRef = useRef()
  const handleEdit = (todo) => {
    setDescription(todo.description)
    setIsEdit(true)
  }
  const checked = useCallback((todo) => {
    return todo.status_id === TODO_DONE_STATUS
  }, [])
  const toggleTodoCheck = (checked, id) => {
    const status_id = checked ? TODO_NOT_DONE_STATUS : TODO_DONE_STATUS
    const data = { status_id, done_dt: moment() }
    if (status_id === TODO_NOT_DONE_STATUS) {
      data["done_dt"] = null
    }
    if (handleSaveTodo) {
      dispatch(updateDraftTodo(Object.assign({}, todo, data)))
    } else {
      dispatch(updateTodoTh(id, data, container))
    }

  }
  const handleClear = () => {
    setEditableTodo(0)
    setDescription("")
    setIsEdit(false)
  }
  const inputOnBlur = (todo) => {
    if (todo.description !== description) {
      if (handleSaveTodo) {
        dispatch(updateDraftTodo(Object.assign({}, todo, { description })))
      } else {
        handleInlineEdit(todo.id)
      }

    }
    handleClear()
  }
  const handleInlineEdit = (todo) => {
    dispatch(updateTodoTh(todo, { description }, container))
  }
  const isToday = () => {
    return moment(todo.due_dt).isSame(moment(), "d")
  }
  const dueDateClassName = () => {
    if (isLate()) return "high_priority"
    else return ""
  }
  const isLate = () => {
    return moment(todo.due_dt).isBefore(moment(), "day")
  }
  const renderLabel = (param) => param

  const toggleInlineEditDate = () => {

    switch (true) {
      case Boolean(isToday()): {
        if (handleSaveTodo) {
          dispatch(updateDraftTodo(Object.assign({}, todo, { due_dt: null, due_label: "Later" })))
        } else {
          dispatch(
            updateTodoTh(todo.id, { due_dt: null, due_label: "Later" }, container)
          )
        }
        break
      }
      default: {
        if (todo.due_label === "Later") {
          setPickerIsOpenToParent && setPickerIsOpenToParent(true)
          setDateIsEdit(true)
        } else {
          dispatch(
            updateTodoTh(todo.id, { due_dt: moment().toISOString(), due_label: null }, container)
          )
        }
        break
      }
    }
  }
  const editDueDate = (date) => {
    if (date.$d) {
      if (handleSaveTodo) {
        dispatch(updateDraftTodo(Object.assign({}, todo, { due_dt: moment(date.$d).toISOString(), due_label: "" })))
      } else {
        dispatch(
          updateTodoTh(
            todo.id,
            { due_dt: moment(date.$d).toISOString(), due_label: "" },
            container
          )
        )
      }

    }
    setDateIsEdit(false)
  }
  const disabledDate = (current) => {
    return current && current < moment().endOf("day")
  }

  const onCloseCalendar = (e) => {
    if (e.keyCode === 27) {
      setDateIsEdit((prev) => {
        if (prev) {
          return false
        } else {
          onCloseParentModal && onCloseParentModal(e)
        }
      })
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", onCloseCalendar)
    return () => {
      document.removeEventListener("keydown", onCloseCalendar)
    }
    //eslint-disable-next-line
  }, [])
  return (
    <List.Item style={{ padding: '12px 0' }} className="todo_item">
      <div className="d-flex align-center w-100">
        <Checkbox
          className="todo-check"
          checked={checked(todo)}
          onClick={() => toggleTodoCheck(checked(todo), todo.id)}
          style={{ marginRight: "24px", height: "24px" }}
        />
        {isEdit && editableTodo === todo.id ? (
          <>
            <Input
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  handleClear()
                }
              }}
              style={{ width: "100%", height: "24px" }}
              ref={inputRef}
              onBlur={() => inputOnBlur(todo)}
              value={description}
              onPressEnter={() => {
                if (handleSaveTodo) {

                } else {
                  handleInlineEdit(todo.id)
                }

                handleClear()
              }}
              defaultValue={todo.description}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
          </>
        ) : (
          <>
            <TodoItem
              todo={todo}
              setEditableTodo={setEditableTodo}
              handleEdit={handleEdit}
              isShow={isShow}
            />
            <div
              className="todo-date"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                minWidth: "66px",
              }}
            >



            </div>
            {dateIsEdit ? (
              <div
                style={{ marginLeft: "auto" }}
                ref={dateRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 27) {
                    setPickerIsOpenToParent && setPickerIsOpenToParent(false)
                    setDateIsEdit(false)
                  }
                }}
              >

                <DatePicker
                  placeholder="Today"
                  style={{
                    height: "24px",
                  }}
                  className="list-item date-picker"
                  format="MMM DD"
                  autoFocus
                  disabledDate={disabledDate}
                  onChange={editDueDate}
                  open
                  onOpenChange={(e) => !e && setDateIsEdit(false)}
                  allowClear={false}
                  suffixIcon={false}
                  picker="date"
                  showNow={false}

                />
              </div>
            ) : (
              <>
                {todo.status_id === TODO_DONE_STATUS ?
                  (<div
                    className={`ant-picker`}
                  >

                    <Popover
                      content={<div>Due: {todo.due_dt ? moment(todo.due_dt).format('MMM DD YYYY') : "Later"}, Done: {moment(todo.done_dt).format('MMM DD YYYY')}</div>}
                      trigger="hover"
                    >
                      <div>{moment(todo.done_dt).format('MMM DD')}</div>
                    </Popover>

                  </div>) :
                  (<div
                    onClick={toggleInlineEditDate}
                    className={`ant-picker ${dueDateClassName()}`}
                  >
                    {isToday()
                      ? renderLabel("Today")
                      : todo.due_label
                        ? todo.due_label
                        : <Popover
                          content={<div>Due: {todo.due_dt ? moment(todo.due_dt).format('MMM DD YYYY') : "Later"}</div>}
                          trigger="hover"
                        >
                          {renderLabel(moment(todo.due_dt).format("MMM DD"))}
                        </Popover>}

                  </div>)}

              </>
            )}

            <Popconfirm
              placement="topLeft"
              title='Remove this tasks? There is no undo.'
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => {
                if (handleSaveTodo) {
                  dispatch(removeDraftTodo(todo))
                } else {
                  dispatch(deleteTodo(todo.id, container))
                }
              }}
              okText="Yes"
              cancelText="No"
            >

              <DeleteOutlined
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>

          </>
        )}
        {isShow && <div
          style={{
            position: "absolute",
            right: 155,
            cursor: "pointer",
            display: editableTodo === todo.id ? "none" : 'block'
          }}>
          <Popover trigger='hover' content={<TodoIconPop todo={todo} />}>
            {todo.job && <JobIcon onClick={() => handleOpenModal(todo)} />}
            {todo.contact && <UserOutlined onClick={() => handleOpenModal(todo)} />}
          </Popover>
        </div>}
      </div>
    </List.Item >
  )
}
