import React, { Fragment } from "react"
import {
  AutoComplete,
  Button,
  Col,
  Input,
  Modal,
  Popover,
  Row,
  Tabs,
  Tooltip,
  Typography,
  Popconfirm,
  Select,
  DatePicker
} from "antd"
import {
  CloseCircleOutlined,
  EditOutlined,
  LinkedinFilled,
  IdcardOutlined
} from "@ant-design/icons"
import { CommunicationList, DiscardChanges } from "../index"
import { contactEdit } from "../../store/actions/contacts/edit-contact"
import moment from "moment"
import OutsideClickHandler from "react-outside-click-handler"
import { DATE_FORMAT } from "../../common/config"
import { TodoList } from "../TodoList"
import AddTag from "../add_tag"
import { Link } from "react-router-dom"
import withMemo from "../../store/HOC/with_memo"
import { useAddContact } from "../../hooks/useAddContact"
import ModalCloseIcon from "./modal-close-icon"
import { LinkItem } from "../notes/link_item"
import { FeaturesTypes } from "../../common/constants"
import Spinner, { MiniSpinner } from '../loaders/spinner'
import SourceImage from '../SourceImage'

const { Title } = Typography

const { Option } = AutoComplete

const AddContact = ({
  modalIsVisible = true,
  onClose,
  status,
  saveCompany,
  defaultActiveKey = "1",
  style,
}) => {
  const [defaultKey, setDefaultKey] = React.useState(defaultActiveKey)
  const [showError, setShowError] = React.useState(false)
  const {
    handleCloseOrConfirm,
    data,
    handleChangeData,
    onEditLinUrl,
    contact,
    setData,
    linkInputEl,
    popOverVisible,
    confirmModal,
    setConfirmModal,
    communications,
    todos,
    handleSaveTodo,
    draftTodos,
    unhide,
    hide,
    addTagStep,
    inputRef,
    firstNameRef,
    setAddTagStep,
    handleSubmit,
    onDeleteContact,
    handleCompanySearch,
    handleChangeCompany,
    companiesResults,
    setPickerIsOpen,
    handleRemoveCommunication,
    handleSave,
    urlIsEdit,
    setUrlIsEdit,
    companyIsEdit,
    setCompanyIsEdit,
    isClickable,
    handleOpenCompanyEdit,
    items,
    onToggleConversationModal,
    isLoading,
    isSearchCompanyLoading,
    onChangeBirthday,
    birthdayPicker,
    setBirthdayPicker,
    closeBirthdayPicker,
    hideBcard,
    unhideBcard,
    bcardVisible,
  } = useAddContact(status, onClose, saveCompany, setDefaultKey, setShowError)
  const tabPaneItems = [
    {
      key: "1",
      label: "Сontact Info",
      children: (
        <div style={{ minHeight: "496px" }}>
          <Row style={{ height: "100%" }}>
            <Col span={13}>
              <Title className="contact-modal title name" level={5}>
                Name
                {showError && !data?.first_name?.trim() && (
                  <span style={{ color: "red", marginLeft: 20 }}>Please enter a contact name</span>
                )}
              </Title>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input
                  tabIndex="1"
                  className="contact-modal inpunt name"
                  value={data.first_name}
                  autoFocus
                  ref={firstNameRef}
                  onChange={(e) =>
                    handleChangeData(e.target.value, "first_name")
                  }
                />

                <div style={{ display: "flex", height: "32px" }}>
                  <Popover
                    content={
                      <OutsideClickHandler onOutsideClick={onEditLinUrl}>
                        {!data.lin_url || urlIsEdit ?
                          <Input
                            tabIndex="-1"
                            autoFocus
                            ref={linkInputEl}
                            value={data.lin_url}
                            onChange={(e) => {
                              setUrlIsEdit(true)
                              setData({ ...data, lin_url: e.target.value })
                            }}
                            onPressEnter={onEditLinUrl}
                            className="contact-modal inpunt share-inpunt"
                            allowClear
                            placeholder="Link"
                          /> :
                          <>
                            <LinkItem link={data.lin_url} file />
                            <EditOutlined
                              className="lead-modal description edit-icon"
                              onClick={() => setUrlIsEdit((prev) => !prev)}
                              style={{ marginRight: 5 }}
                            />
                            <Popconfirm
                              placement="right"
                              className="delete_file"
                              title="Clear link ?"
                              onConfirm={() => {
                                hide()
                                setTimeout(() => setData({ ...data, lin_url: "" }), 500)
                              }}
                              okText="Clear"
                              cancelText="Cancel"
                            >
                              <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                            </Popconfirm>

                          </>
                        }

                      </OutsideClickHandler>
                    }
                    trigger="click"
                    open={popOverVisible}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                    <Tooltip placement="bottom" title="Сlick to attach LinkedIn">
                      <div
                        className="contact-modal share-icon"
                        onClick={unhide}
                      >
                        <Link to="#">
                          <LinkedinFilled
                            style={
                              !data.lin_url
                                ? {
                                  color: "#D9D9D9",
                                }
                                : { color: "#009EEF" }
                            }
                          />
                        </Link>
                      </div>
                    </Tooltip>
                    {data?.bcard && <>
                      <Popover
                        align="right"
                        trigger="click"
                        open={data?.bcard?.url && bcardVisible}
                        content={
                          <OutsideClickHandler onOutsideClick={hideBcard}>
                            <SourceImage url={data?.bcard?.url} />
                          </OutsideClickHandler>
                        }
                      />
                      <div onClick={unhideBcard} className="bcard-icon">
                        <IdcardOutlined style={{
                          color: "white",
                          fontSize: 20
                        }} />
                      </div>
                    </>}

                  </div>
                </div>

              </div>

              <Title className="contact-modal title" level={5}>
                Title
              </Title>
              <Input
                tabIndex="2"
                className="contact-modal inpunt"
                value={data.title}
                onChange={(e) => handleChangeData(e.target.value, "title")}
              />
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "11px" }}>
                  <Title className="contact-modal title small" level={5}>
                    Office
                  </Title>
                  <Input
                    tabIndex="3"
                    className="contact-modal inpunt small phone"
                    value={data.phone}
                    onChange={(e) => handleChangeData(e.target.value, "phone")}
                  />
                </div>
                <div>
                  <Title className="contact-modal title small" level={5}>
                    Mobile
                  </Title>
                  <Input
                    tabIndex="4"
                    className="contact-modal inpunt small"
                    value={data.mobile}
                    onChange={(e) => handleChangeData(e.target.value, "mobile")}
                  />
                </div>
              </div>
              <Title className="contact-modal title" level={5}>
                Email
              </Title>
              <Input
                tabIndex="6"
                className="contact-modal inpunt"
                value={data.email}
                onChange={(e) => handleChangeData(e.target.value, "email")}
              />
              <Title className="contact-modal title" level={5}>
                Address
              </Title>
              <Input
                tabIndex="7"
                className="contact-modal inpunt location small"
                placeholder="Street"
                value={data.street_1}
                onChange={(e) => handleChangeData(e.target.value, "street_1")}
              />
              <Input
                tabIndex="8"
                className="contact-modal inpunt small"
                placeholder="City, State, ZIP"
                value={data.city}
                onChange={(e) => handleChangeData(e.target.value, "city")}
              />
              <AddTag
                item={contact}
                addTagStep={addTagStep}
                inputRef={inputRef}
                setAddTagStep={setAddTagStep}
                update={contactEdit}
                type={FeaturesTypes.CONTACT}
              />



            </Col>

            <Col
              span={11}
              style={{
                textAlign: "right",
                position: "relative",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around", textAlign: 'left', paddingTop: '12px' }}>
                <div >
                  <Title className="contact-modal title" level={5}>
                    Birthday
                  </Title>
                  <DatePicker
                    placeholder=" "
                    style={{ padding: "15px 10px", marginLeft: '2px' }}
                    value={data?.birthday}
                    onChange={onChangeBirthday}
                    open={birthdayPicker}
                    onOpenChange={setBirthdayPicker}
                    onKeyDown={closeBirthdayPicker}
                    showNow={false}
                  />
                </div>
                <div style={{ textAlign: "left" }}>
                  <Title className="contact-modal title" level={5} >
                    Contact Reminders
                  </Title>
                  <Select
                    style={{ minWidth: 75 }}
                    options={items}
                    defaultValue={!data?.reminders ? "0" : data?.reminders}
                    onChange={(e) => {
                      const key = e === "0" ? "" : e
                      handleChangeData(key, "reminders")
                    }}
                  />
                </div>
              </div>
              <Title className="contact-modal right title" level={5}>
                Company
              </Title>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "initial",
                  height: "32px",
                }}
              >
                {(!data?.company?.name) || companyIsEdit
                  ?
                  <div style={{ position: "relative" }}>
                    {isSearchCompanyLoading && <div style={{ position: 'absolute', right: "3%", top: '10%', zIndex: 10 }}>
                      <MiniSpinner /></div>}
                    <AutoComplete
                      tabIndex="9"
                      className="contact-modal auto-complete"
                      onSearch={handleCompanySearch}
                      autoFocus
                      allowClear
                      value={data?.company?.name}
                      onChange={(company) => {
                        setCompanyIsEdit(true)
                        handleChangeCompany(company)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setCompanyIsEdit(false)
                        }
                      }}
                      onSelect={() => setCompanyIsEdit(false)}
                      onBlur={() => setCompanyIsEdit(false)}
                    >
                      {data?.company?.name?.trim() && companiesResults?.map((company) => (
                        <Option value={company.name} key={company.id}>
                          {company.name}
                        </Option>
                      ))}
                    </AutoComplete>

                  </div>
                  :
                  <div style={{

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: '4px 1px 6px 32px'
                  }}>
                    <span
                      onClick={handleOpenCompanyEdit}
                      className="search-span-result"
                      style={{
                        color: isClickable ? "#009EEF" : "rgba(0, 0, 0, 0.25)",
                        cursor: isClickable ? "pointer" : "default",
                        pointerEvents: isClickable ? "auto" : "none",
                      }}>
                      {data?.company?.name}
                    </span>
                    <span>
                      <EditOutlined
                        className="lead-modal description edit-icon"
                        onClick={() => setCompanyIsEdit((prev) => !prev)}
                        style={{ marginRight: 5 }}
                      />
                      <Popconfirm
                        placement="right"
                        className="delete_file"
                        title="Clear company ?"
                        onConfirm={() => {
                          setTimeout(() => handleChangeCompany(""), 500)
                        }}
                        okText="Clear"
                        cancelText="Cancel"
                      >
                        <CloseCircleOutlined style={{ color: "red", fill: "red", cursor: "pointer" }} />
                      </Popconfirm>
                    </span>
                  </div>
                }

              </div>
              <Title className="contact-modal right title mobile" level={5}>
                Personal Mobile
              </Title>
              <Input
                tabIndex="5"
                value={data.personal_mobile}
                onChange={(e) => {
                  handleChangeData(e.target.value, "personal_mobile")
                }}
                className="contact-modal right inpunt mobile"
              />
              <Title className="contact-modal right title" level={5}>
                Personal Email
              </Title>
              <Input
                tabIndex="10"
                value={data.personal_email}
                onChange={(e) => {
                  handleChangeData(e.target.value, "personal_email")
                }}
                className="contact-modal right inpunt"
              />
              <Title className="contact-modal right title" level={5}>
                Notes
              </Title>
              <Input.TextArea
                tabIndex="11"
                className="contact-modal inpunt notes"
                value={data.notes || ""}
                onChange={(e) => handleChangeData(e.target.value, "notes")}
              />


            </Col>
          </Row >
          <div style={{ display: "flex", justifyContent: 'space-between' }}>



            <Popconfirm
              placement="topRight"
              className="delete_file"
              title="Delete contact? There is no undo."
              onConfirm={onDeleteContact}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button
                danger
                disabled={status === "add"}
                type="primary"
              >
                Delete
              </Button>
            </Popconfirm>

            <Button

              type="primary"
              onClick={handleSubmit}
            >
              Done
            </Button>
          </div>
        </div >
      ),
    },
    {
      key: "2",
      label: "Tools",
      children: (
        <div style={{ minHeight: "496px" }}>
          {isLoading && <div style={{ position: 'absolute', right: "45%" }}><Spinner /></div>}
          <Row style={{ height: "100%" }}>
            <Col span={13}>
              <Title className="contact-modal todo-title" level={3}>
                Tasks
              </Title>
              <TodoList
                todos={status === 'add' ? draftTodos : todos.filter((todo) => todo?.contact === contact?.id)}
                containerId={contact?.id}
                showTodoInput
                setPickerIsOpenToParent={setPickerIsOpen}
                onCloseParentModal={handleCloseOrConfirm}
                handleSaveTodo={status === "add" ? handleSaveTodo : undefined}
                isModal={true}
                isContact={true}
                placement="modal"
              />
              <Title className="contact-modal title notes" level={5}>
                Notes
              </Title>
              <Input.TextArea
                className="contact-modal inpunt notes workspace"
                value={data.notes || ""}
                onChange={(e) => handleChangeData(e.target.value, "notes")}
              />
              <div style={{ height: "32px" }}>
                <Button
                  style={{ position: "absolute", bottom: "0", left: "0" }}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Done
                </Button>
              </div>
            </Col>
            <Col style={{ borderRadius: "5px" }} span={11}>
              <CommunicationList
                handleRemove={handleRemoveCommunication}
                handleSave={handleSave}
                toggleCommuniCationModal={onToggleConversationModal}
                communications={
                  status === "edit"
                    ? communications.published?.filter(el => el.contact === contact?.id)
                    : communications.drafts
                }
              />
              {contact && (
                <div style={{ height: "60px" }}>
                  <Title
                    className="contact-modal title name-input"
                    level={5}
                    style={{ fontWeight: "600" }}
                  >
                    {data.first_name || ""}
                  </Title>
                  <Title
                    className="contact-modal title dates"
                    level={5}
                    style={{ fontSize: "12px" }}
                  >
                    Create: {moment(contact.create_dt).format(DATE_FORMAT)}
                  </Title>
                  {contact?.last_edit_dt && (
                    <Title
                      className="contact-modal title editing"
                      level={5}
                      style={{ fontSize: "12px" }}
                    >
                      Last edit:{" "}
                      {moment(contact.last_edit_dt).format(DATE_FORMAT)}
                    </Title>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      ),
    },
  ]
  return (
    <Fragment>
      <Modal
        closeIcon={<ModalCloseIcon />}
        className={"contact_modal savvyy-modal"}
        footer={null}
        open={modalIsVisible}
        onCancel={handleCloseOrConfirm}
        width={800}
        style={style}
      >
        <Tabs
          style={{ pointerEvents: isLoading ? "none" : "auto" }}
          type="card"
          items={tabPaneItems}
          activeKey={defaultKey}
          onChange={setDefaultKey}
        />
      </Modal>
      {confirmModal && (
        <DiscardChanges
          confirmModal={confirmModal}
          handleCancelConfirmModal={() => setConfirmModal(false)}
          onSubmitConfirmModal={onClose}
        />
      )}
    </Fragment>
  )
}
export default withMemo(AddContact)
