import { useState } from "react"
import { Popover } from "antd"
import { TODO_DONE_STATUS } from "../common/constants"

export const TodoItem = ({ todo, handleEdit, setEditableTodo, isThrough = true, isShow = false, placement = 'dash' }) => {
  const [maxLength] = useState(isShow ? 80 : 26)
  const checked = () => todo.status_id === TODO_DONE_STATUS
  const content = <span>{todo.description}</span>
  const needToPopover = Boolean(todo?.description?.length > maxLength)
  const isEntity = todo?.contact || todo?.job
  const width = {
    dash: isEntity ? '75%' : '79%',
    modal: '50%',
    timeline: isEntity ? '64%' : '66%',
    timeline_done: isEntity ? '57%' : '61%',
  }
  const styles = {
    textDecoration: checked() && isThrough ? "line-through" : "none",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: width[placement],
    textOverflow: 'ellipsis',
    textAlign: 'left',

  }
  return needToPopover ? (
    <Popover content={content} trigger="hover">
      <span
        className="todo-task"
        style={styles}
        onDoubleClick={() => {
          handleEdit(todo)
          setEditableTodo(todo.id)
        }}
      >
        {todo.description}
      </span>
    </Popover>
  ) : (
    <span
      onDoubleClick={() => {
        if (todo) {
          handleEdit(todo)
          setEditableTodo(todo.id)
        }
      }}
      className="todo-task"
      style={styles}
    >
      {todo.description}
    </span>
  )
}
