import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getJobsSelector, getContactsSelector } from "../store/selectors"
import { getJobToList } from "../store/actions/jobs/get-jobs"
import { getContact } from "../store/actions/contacts/get-contacts-list"
import {
  getSpecificCommunicationContact,
  getSpecificCommunicationJob,
} from "../store/selectors/communications/get-communications-list"
import {
  setSpecificCommunicationContact,
  setSpecificCommunicationJob,
} from "../store/actions/communication/create-communication"
import { useAction } from "./useAction"

export const useCommunicationSpecData = (communication) => {
  const contact = useSelector(getSpecificCommunicationContact)
  const job = useSelector(getSpecificCommunicationJob)
  const allContacts = useSelector(getContactsSelector)
  const allJobs = useSelector(getJobsSelector)
  const getContactToListById = useAction(getContact)
  const getJobToListById = useAction(getJobToList)
  const setJob = useAction(setSpecificCommunicationJob)
  const setContact = useAction(setSpecificCommunicationContact)

  const communicationDataId = communication?.contact || communication?.job

  const entity = async (id, mode) => {
    try {
      if (mode === "job") {
        return await getJobToListById(id)
      } else if (mode === "contact") {
        return await getContactToListById(id)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (communication.contact) {
      const contactFromRedux = allContacts.find(
        (contact) => contact.id === communication.contact
      )
      if (contactFromRedux) {
        setContact(contactFromRedux)
      } else {
        entity(communication.contact, "contact").then((res) => setContact(res))
      }
    }
    if (communication.job) {
      const jobFromRedux = allJobs.find((job) => job.id === communication.job)
      if (jobFromRedux) {
        setJob(jobFromRedux)
      } else {
        entity(communication.job, "job").then((res) => setJob(res))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    communicationDataId,
    contact,
    job,
  }
}
