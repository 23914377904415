import React, { useCallback, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import moment from "moment"
import { CalendarOutlined } from "@ant-design/icons"
import { DatePicker, Input, message } from "antd"
import { createTodoTH } from "../store/actions/todos/create_todo"
import { useDispatch, useSelector } from "react-redux"
import { getSpecificContact } from "../store/selectors/contacts/get-contacts-selector"
import { getSpecificJob } from "../store/selectors"
import TodoActions from "./todo/todo_actions"
import { URLS } from "../common/urls"
import { getTodos } from "../store/actions"

export const AddTodo = ({
  clickCount,
  setClickCount,
  setPickerIsOpen,
  pickerIsOpen,
  showTodoInput,
  handleSaveTodo,
  isModal = false,
  isContact = false,
}) => {
  const dispatch = useDispatch()
  const addTodoRef = useRef()
  const contact = useSelector(getSpecificContact)
  const job = useSelector(getSpecificJob)
  const location = useLocation()
  const [messsageApi, contextHolder] = message.useMessage()
  const [todo, setTodo] = useState({
    description: "",
    due_dt: 0,
  })

  const closeRef = useRef(null)

  const handleAddTodo = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        let due_dt
        let due_label = ""
        switch (clickCount) {
          case 0: {
            due_dt = moment().toISOString()
            break
          }
          case 1: {
            due_label = "Later"
            break
          }
          case 4:
          case 2: {
            if (todo.due_dt === 0) {
              due_dt = moment().toISOString()
            } else {
              due_dt = todo.due_dt
            }
            break
          }
          default: {
            due_dt = moment().toISOString()
            break
          }
        }
        const data = { ...todo, status_id: 1, due_dt, drag_index: 0, due_label }
        if (handleSaveTodo) {
          handleSaveTodo(data)
          setTodo({
            description: "",
          })
        } else {
          if (!!contact?.id && isContact) {
            data.contact = contact?.id
          }
          if (!!job?.id && !isContact) {
            data.job = job.id
          }
          dispatch(createTodoTH(data, !!contact?.id, !!job?.id)).then(
            () => {
              setClickCount(0)
              openNotification()
              if (location.pathname === URLS.HOME && !!job?.id) {
                dispatch(getTodos())
              }
            }
          )
          setTodo({
            description: "",
          })
        }
      }


    },
    //eslint-disable-next-line
    [clickCount, contact, dispatch, job, setClickCount, setTodo, todo]
  )

  const openNotification = () => {
    return messsageApi.open({
      type: "info",
      content: <div>Added</div>,
      duration: 5,
    })
  }
  const datePickerRef = useRef()
  const closeDatePicker = (e = null) => {
    if (e?.key === "Escape" || e?.type === "blur") {
      e.stopPropagation()
      if (pickerIsOpen) {
        setClickCount(4)
      } else {
        setClickCount(1)
      }
    }
  }

  const onChangeClickCount = useCallback(() => {

    switch (clickCount) {
      default:
      case 0:
        setClickCount(1)
        addTodoRef.current.focus()
        setPickerIsOpen(false)
        break
      case 1:
        addTodoRef.current.focus()
        setPickerIsOpen(false)
        setClickCount(3)
        break
      case 4:
        setPickerIsOpen(false)
        setTodo({ ...todo, due_dt: 0 })
        setClickCount(0)
        break

    }
  }, [clickCount, setClickCount, setPickerIsOpen, todo])
  const handlePickerOpenChange = useCallback(() => {
    if (!closeRef.current) setClickCount(1)
    closeRef.current = null
  }, [setClickCount])

  const onChangePicker = useCallback((val) => {
    closeRef.current = true
    addTodoRef.current.focus()
    setTodo({ ...todo, due_dt: val.$d ? val.$d : 0 })
    setClickCount(4)
  }, [todo, setClickCount])
  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "day").endOf("day")
  }
  return (
    <div
      className="desktop-todo actions"
      style={{ width: "100%", marginBottom: "9px", position: "relative" }}
    >
      <TodoActions />
      <div
        style={{
          display: "flex",
        }}
      >
        <Input
          ref={addTodoRef}
          value={todo.description}
          onPressEnter={handleAddTodo}
          onChange={(e) =>
            setTodo({ ...todo, description: e.currentTarget.value })
          }
          placeholder="What needs doing?"
          style={{ borderRadius: "6px 6px 6px 6px", paddingRight: "110px" }}
        />
        {contextHolder}
        <div
          onClick={clickCount !== 3 ? onChangeClickCount : null}
          className={clickCount !== 3 ? `${isModal ? "ant-picker create-modal-todo-picker" : "ant-picker create-todo-picker"}` : ""}
        >
          {clickCount === 0 ? (
            <span
              style={{ width: "100%", textAlign: "center" }}
            >
              Today
            </span>
          ) : clickCount === 1 ? (
            <span
              style={{ width: "100%", textAlign: "center" }}
            >
              Later
            </span>
          ) : clickCount === 4 ? (
            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <div style={{ width: "59px" }}>
                <span style={{ textAlign: "center" }}>
                  {moment(todo.due_dt).format("MMM DD")}
                </span>
              </div>
              <CalendarOutlined
                onClick={(e) => {
                  e.stopPropagation()
                  setPickerIsOpen(true)
                  setClickCount(3)
                }}
              />
            </div>
          ) : clickCount === 3 ? (
            <DatePicker
              id="date-picker"
              className={`${isModal ? "create-modal-todo-picker" : "create-todo-picker"}`}
              allowClear={false}
              onKeyDown={closeDatePicker}
              autoFocus
              disabledDate={disabledDate}
              onOpenChange={handlePickerOpenChange}
              open
              format="MMM DD"
              ref={datePickerRef}
              picker="date"
              showNow={false}
              placeholder="Today"

              onChange={onChangePicker}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
