import { SET_TODOS_DATA } from "../../action-types/todos"
import { request } from "../../../api/axios_helper"
import {
  TODO_NOT_DONE_STATUS,
  TODOS_API_URL,
  DIARIES_ACTIVITY_TYPE,
} from "../../../common/constants"
import { sortTodoStrategy } from "../../../common/helpers"
import { setTodoPendingStatus } from "./create_todo"
import { getTodoCurrentFilter } from "../../selectors"
import moment from "moment"

export const setTodos = (payload) => {
  return {
    type: SET_TODOS_DATA,
    payload,
  }
}
export const getTodos = () => async (dispatch, getState) => {
  try {
    dispatch(setTodoPendingStatus(true))
    const filter = getTodoCurrentFilter(getState())
    const url =
      filter === TODO_NOT_DONE_STATUS
        ? `${TODOS_API_URL}?status_id=${TODO_NOT_DONE_STATUS}`
        : TODOS_API_URL

    const req = request()
    const res = await req.get(url, dispatch)

    let todayTodos = []
    if (filter === TODO_NOT_DONE_STATUS) {
      const todayUrl = `${DIARIES_ACTIVITY_TYPE}todo_done/?date=${moment().format(
        "YYYYMMDD"
      )}`
      const { status, data } = await req.get(todayUrl, dispatch)
      if (status === 200) {
        todayTodos = data
      }
    }
    if (res.status === 200) {
      if (todayTodos.length > 0) {
        const resultTodos = todayTodos.slice().sort(sortTodoStrategy)
        dispatch(setTodos([...resultTodos, ...res.data]))
      } else {
        dispatch(setTodos(res.data))
      }
    }
  } catch (err) {
  } finally {
    dispatch(setTodoPendingStatus(false))
  }
}
