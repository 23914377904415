import React, { useCallback, useRef, useState, useEffect } from "react"
import { Checkbox, DatePicker, Input, Popover, Popconfirm } from "antd"
import Text from "antd/es/typography/Text"
import { useSelector } from "react-redux"
import moment from "moment"
import { TodoItem } from "../TodoItem"
import { FeaturesTypes, TODO_DONE_STATUS, TODO_NOT_DONE_STATUS } from "../../common/constants"
import { useAction } from "../../hooks/useAction"
import { deleteTodo } from "../../store/actions/todos/delete"
import { updateTodoTh } from "../../store/actions/todos/update-todo"
import { DeleteOutlined } from "@ant-design/icons"
import { DATE_FORMAT } from "../../common/config"
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons"
import { ReactComponent as JobIcon } from "../../assets/images/job_icon.svg"
import { getContact } from "../../store/actions/contacts/get-contacts-list"
import { getJobToList } from "../../store/actions/jobs/get-jobs"
import { getContactsSelector, getJobsSelector } from "../../store/selectors"
import TodoPop from './todoIconPop'


export const DailyTodoItem = ({
    todo,
    setPickerIsOpenToParent,
    onCloseParentModal,
    status,
    handleClick,
    placement
}) => {
    const [dateIsEdit, setDateIsEdit] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [description, setDescription] = useState("")
    const inputRef = useRef()
    const dateRef = useRef()
    const contacts = useSelector(getContactsSelector)
    const jobs = useSelector(getJobsSelector)
    const updateTodoAction = useAction(updateTodoTh)
    const deleteTodoAction = useAction(deleteTodo)
    const getContactAction = useAction(getContact)
    const getJobAction = useAction(getJobToList)



    const handleClickIcon = async (type, id) => {
        if (type === FeaturesTypes.CONTACT) {
            const contact = contacts.find((item) => item.id === id)
            if (contact) {
                handleClick(contact, FeaturesTypes.CONTACT, contact)
            } else {
                const res = await getContactAction(id)
                handleClick(res, FeaturesTypes.CONTACT, res)
            }

        } else if (type === FeaturesTypes.LEAD) {
            const job = jobs.find((item) => item.id === id)
            if (job) {
                handleClick(job, FeaturesTypes.LEAD, job)
            } else {
                const res = await getJobAction(id)
                handleClick(res, FeaturesTypes.LEAD, res)
            }
        }
    }


    const handleEdit = (todo) => {
        setDescription(todo.description)
        setIsEdit(true)
        setTimeout(() => {
            inputRef?.current?.focus()
        }, 400)
    }
    const checked = useCallback((todo) => {
        return todo.status_id === TODO_DONE_STATUS
    }, [])
    const toggleTodoCheck = async (checked, id) => {
        const status_id = checked ? TODO_NOT_DONE_STATUS : TODO_DONE_STATUS
        const data = { status_id, done_dt: moment() }
        if (status_id === TODO_NOT_DONE_STATUS) {
            data["done_dt"] = null
        }
        await updateTodoAction(id, data, undefined, status)


    }
    const inputOnBlur = async (todo) => {
        if (todo.description !== description) {

            await handleInlineEdit(todo.id)


        }
        setIsEdit(false)
    }
    const handleInlineEdit = async (todo) => {
        await updateTodoAction(todo, { description }, undefined, status)
    }
    const isToday = () => {
        return moment(todo.due_dt).isSame(moment(), "d")
    }
    const dueDateClassName = () => {
        if (isLate()) return "high_priority"
        else return ""
    }
    const isLate = () => {
        return moment(todo.due_dt).isBefore(moment(), "day")
    }
    const renderLabel = (param) => {
        return isLate() ? (
            <Popover
                content={<div>{moment(todo.due_dt).format(DATE_FORMAT)}</div>}
                trigger="hover"
            >
                <div>Late</div>
            </Popover>
        ) : (
            param
        )
    }
    const toggleInlineEditDate = async () => {

        switch (true) {
            case Boolean(isToday()): {
                await updateTodoAction(todo.id, { due_dt: null, due_label: "Later" }, undefined, status)
                break
            }
            default: {
                if (todo.due_label === "Later") {
                    setPickerIsOpenToParent && setPickerIsOpenToParent(true)
                    setDateIsEdit(true)
                } else {
                    await updateTodoAction(todo.id, { due_dt: moment().toISOString(), due_label: null }, undefined, status)
                }
                break
            }
        }
    }
    const editDueDate = async (date) => {
        if (date.$d) {
            await updateTodoAction(
                todo.id,
                { due_dt: moment(date.$d).toISOString(), due_label: "" },
                undefined, status
            )
        }
        setDateIsEdit(false)
    }
    const disabledDate = (current) => {
        return current && current < moment().endOf("day")
    }

    const onCloseCalendar = (e) => {
        if (e.keyCode === 27) {
            setDateIsEdit((prev) => {
                if (prev) {
                    return false
                } else {
                    onCloseParentModal && onCloseParentModal(e)
                }
            })
        }
    }
    useEffect(() => {
        document.addEventListener("keydown", onCloseCalendar)
        return () => {
            document.removeEventListener("keydown", onCloseCalendar)
        }
        //eslint-disable-next-line
    }, [])
    return (
        <div className="daily_todo todo_item" style={{ marginTop: "7px" }}>
            <div className="d-flex align-center w-100">
                <Checkbox
                    className="todo-check"
                    checked={checked(todo)}
                    onClick={() => toggleTodoCheck(checked(todo), todo.id)}
                    style={{ marginRight: "24px", height: "24px" }}
                />
                {isEdit ? (
                    <Input
                        onKeyDown={(e) => {
                            if (e.keyCode === 27) {
                                setDescription("")
                                setIsEdit(false)
                            }
                        }}
                        style={{ width: "100%", height: "24px" }}
                        ref={inputRef}
                        onBlur={async () => await inputOnBlur(todo)}
                        value={description}
                        onPressEnter={async () => {
                            await handleInlineEdit(todo.id)
                            setDescription("")
                            setIsEdit(false)
                        }}
                        defaultValue={todo.description}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                    />
                ) : (
                    <>
                        <TodoItem
                            todo={todo}
                            setEditableTodo={() => { }}
                            handleEdit={handleEdit}
                            isThrough={false}
                            placement={placement}
                        />
                        <div style={{ position: "absolute", right: todo.status_id === TODO_DONE_STATUS ? "32%" : "25%", top: "33%", cursor: "pointer" }}>
                            <Popover content={<TodoPop todo={todo} />}
                                trigger="hover">
                                {todo.job && <JobIcon onClick={() => handleClickIcon(FeaturesTypes.LEAD, todo.job)} />}
                                {todo.contact && <UserOutlined onClick={() => handleClickIcon(FeaturesTypes.CONTACT, todo.contact)} />}
                            </Popover>
                        </div>
                        {todo.status_id === TODO_DONE_STATUS ?
                            <Text style={{ marginLeft: "auto", marginRight: 0 }} className="diary_drawer_date large-date">
                                Done: {moment(todo?.done_dt).format(DATE_FORMAT)}
                            </Text> : dateIsEdit ? (
                                <div
                                    style={{ marginLeft: "auto" }}
                                    ref={dateRef}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 27) {
                                            setPickerIsOpenToParent && setPickerIsOpenToParent(false)
                                            setDateIsEdit(false)
                                        }
                                    }}
                                >

                                    <DatePicker
                                        placeholder="Today"
                                        style={{
                                            height: "24px",
                                        }}
                                        className="list-item date-picker"
                                        format="MMM DD"
                                        autoFocus
                                        disabledDate={disabledDate}
                                        onChange={editDueDate}
                                        open
                                        onOpenChange={(e) => !e && setDateIsEdit(false)}
                                        allowClear={false}
                                        suffixIcon={false}
                                        picker="date"
                                        showNow={false}

                                    />
                                </div>
                            ) : (

                                <div
                                    onClick={toggleInlineEditDate}
                                    className={`ant-picker ${dueDateClassName()}`}
                                >
                                    {isToday()
                                        ? renderLabel("Today")
                                        : todo.due_label
                                            ? todo.due_label
                                            : renderLabel(moment(todo.due_dt).format("MMM DD"))}
                                </div>
                            )}

                        <Popconfirm
                            placement="topLeft"
                            title='Remove this tasks? There is no undo.'
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => {
                                deleteTodoAction(todo.id, undefined, status)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >

                            <DeleteOutlined
                                style={{ marginLeft: "12px", color: "red" }}
                            />
                        </Popconfirm>

                    </>
                )}
            </div>
        </div >
    )
}
