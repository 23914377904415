import React, { useCallback, useMemo, useState } from "react"
import { useAction } from "./useAction"
import { downloadFile } from "../store/actions/file_manager/download-file"
import { LinkItem } from "../components/notes/link_item"
import {
  firstLetterToUpperCase,
  removeExtension,
  renderTitleByType,
} from "../common/helpers"
import Text from "antd/es/typography/Text"
import { getCurrentFilePendingStatus } from "../store/selectors"
import { useSelector } from "react-redux"
import { LinkOutlined, PaperClipOutlined } from "@ant-design/icons"
import Icon from "@ant-design/icons"
import { Spin, Tag } from "antd"
import { useActivityItem } from "./useActivityItem"
import Note from "../components/notes/note"
import { FeaturesTypes } from "../common/constants"
import { getCurrentDiaryDate } from "../store/selectors/diaries/get_diaries_activities"
import { useColors } from "./useColors"
import DiaryContent from "../components/diaries/diary_content"
import { DailyTodoItem } from "../components/todo/daily_todo_item"
import { getUserSubscriptionTypeSelector } from "../store/selectors"

export const useDiaryDrawer = ({
  diaryContent,
  diaryHtml,
  setDiaryContent,
  setDiaryHtml,
}) => {
  const date = useSelector(getCurrentDiaryDate)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const { allColors } = useColors()

  const listContentStyle = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    }),
    []
  )
  const listStyle = useMemo(
    () => ({
      height: "auto",
      overflow: "hidden",
      overflowY: "scroll",
      marginBottom: "1rem",
      maxHeight: "250px",
      width: "100%",
    }),
    []
  )
  const [notesConfig, setNoteConfig] = useState([])
  const currentPendingFile = useSelector(getCurrentFilePendingStatus)
  const handleDownloadFile = useAction(downloadFile)
  const [drawerIsEditing, setDrawerIsEditing] = useState(false)
  const { handleClick: handleOpenActivityModal } = useActivityItem({})

  const handleExpandNote = useCallback(
    (e) => {
      e.stopPropagation()
      const _note = JSON.parse(e.currentTarget.dataset.config)
      const isExist = notesConfig.find((note) => note.id === _note.id)
      if (isExist) {
        setNoteConfig((prev) => {
          return prev.map((note) => {
            return +note.id === +_note.id
              ? {
                  id: _note.id,
                  expanded: !note.expanded,
                }
              : note
          })
        })
        return
      }
      setNoteConfig((prev) => [...prev, { id: _note.id, expanded: true }])
    },
    [notesConfig]
  )
  const isLarge = useCallback((activity, key) => {
    if (key === "diary") {
      const body = activity.body_txt
      return body?.length > 100
    } else if (key === "communication") {
      return activity?.body?.length > 40
    } else if (key === "note") {
      return false
    }
  }, [])

  const showAddedDate = (key, activity) => {
    const isCommunicationOrNote =
      key === FeaturesTypes.COMMUNICATION || key === FeaturesTypes.NOTE
    const isNotCommOrNote =
      key !== FeaturesTypes.COMMUNICATION && key !== FeaturesTypes.NOTE
    return (isCommunicationOrNote && !isLarge(activity, key)) || isNotCommOrNote
  }

  const showAddedDateX = (key, activity) => {
    const isCommunicationOrNote =
      key === FeaturesTypes.COMMUNICATION || key === FeaturesTypes.NOTE
    const isNotCommOrNote =
      key !== FeaturesTypes.COMMUNICATION && key !== FeaturesTypes.NOTE
    return (
      ((isCommunicationOrNote && !isLarge(activity, key)) || isNotCommOrNote) &&
      !drawerIsEditing
    )
  }

  const DiaryEditSvg = () => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0932 1.79298L10.0163 0.715577C9.99419 0.694008 9.9793 0.666277 9.97211 0.635978C9.96544 0.605679 9.96698 0.574353 9.97725 0.545081C9.987 0.515809 10.0055 0.490132 10.0291 0.470618C10.0533 0.451103 10.0825 0.438778 10.1133 0.435183L13.8149 0.00175591C13.9202 -0.010569 14.0106 0.0782732 13.9983 0.185603L13.5648 3.8872C13.5484 4.02328 13.3815 4.0808 13.2844 3.98426L12.2034 2.90274L6.91707 8.18912C6.85287 8.25331 6.74811 8.25331 6.68392 8.18912L5.80885 7.31405C5.74517 7.25037 5.74517 7.14509 5.80885 7.0809L11.0932 1.79298Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M13.4399 14H0.560122C0.250443 14 0 13.75 0 13.4403V0.560122C0 0.250443 0.250443 0 0.560122 0H6.85997C6.93706 0 7.00022 0.0631542 7.00022 0.140247V1.12024C7.00022 1.19734 6.93706 1.26006 6.85997 1.26006H1.26006V12.7404H12.7399V7.14003C12.7399 7.06337 12.8031 7.00022 12.8802 7.00022H13.8602C13.9368 7.00022 14 7.06337 14 7.14003V13.4403C14 13.75 13.75 14 13.4399 14Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  )

  const renderContent = useCallback(
    (activity, key, Component = null, title) => {
      switch (key) {
        case FeaturesTypes.LINK: {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="edit-header">
                <Icon
                  component={DiaryEditSvg}
                  style={{ marginLeft: "8px" }}
                  onClick={(e) => handleOpenActivityModal(e, key, activity)}
                />
              </div>
              <LinkItem
                caption={activity.title || activity.caption}
                link={activity.url}
                file
              />
              <Component activity={activity} keys={key} title={title} />
            </div>
          )
        }
        case FeaturesTypes.FILES: {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="edit-header">
                <Icon
                  component={DiaryEditSvg}
                  style={{ marginLeft: "8px" }}
                  onClick={(e) => handleOpenActivityModal(e, key, activity)}
                />
              </div>
              <LinkItem
                style={{ marginRight: "1rem" }}
                caption={removeExtension(activity.title || activity.orig_name)}
                file={activity}
                callback={handleDownloadFile}
              />
              {activity.id === currentPendingFile.id &&
                currentPendingFile.pending && <Spin />}
              <Component activity={activity} keys={key} title={title} />
            </div>
          )
        }
        case FeaturesTypes.COMMUNICATION: {
          const body = activity.body
          const currentIsExpanded = notesConfig.some(
            (conf) => conf.expanded && conf.id === activity.id
          )
          const _content = currentIsExpanded ? (
            body
          ) : (
            <>
              {body?.substring(0, 40)}
              {isLarge(activity, key) && <span> ... </span>}
            </>
          )
          return (
            <>
              <Note
                setDrawerIsEditing={setDrawerIsEditing}
                diary_date={date}
                item={activity}
                handleClick={handleOpenActivityModal}
                itemKey={key}
                content={_content}
                isLarge={isLarge}
                body={body}
                handleExpandNote={handleExpandNote}
                currentIsExpanded={currentIsExpanded}
                allColors={allColors}
              />
            </>
          )
        }
        case FeaturesTypes.NOTE: {
          const body = activity.body_txt
          const currentIsExpanded = notesConfig.some(
            (conf) => conf.expanded && conf.id === activity.id
          )

          const _content = body ? (
            currentIsExpanded ? (
              body
            ) : (
              body
            )
          ) : activity?.title ? (
            ""
          ) : (
            <span style={{ color: "rgb(210, 210, 210)" }}>
              Empty note, click to add
            </span>
          )

          return (
            <div onClick={(e) => handleOpenActivityModal(e, key, activity)}>
              <div style={{ display: "flex" }}>
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "225px",
                    }}
                  >
                    {activity?.title}
                  </p>
                  <p
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "225px",
                    }}
                  >
                    {_content}
                  </p>
                </div>
                <Component activity={activity} keys={key} title={title} />
                <div className="edit-header">
                  <Icon
                    component={DiaryEditSvg}
                    style={{ marginLeft: "8px" }}
                    onClick={() => {}}
                  />
                </div>
              </div>

              <div style={{ padding: 1 }}>
                {activity?.links?.length >= 0 &&
                  activity.links.map((link) => (
                    <Tag
                      style={{ borderRadius: "50px", margin: 1 }}
                      key={link.id}
                    >
                      <LinkOutlined style={{ margin: "0px 5px" }} />
                      <LinkItem
                        style={{ margin: "0px 1px" }}
                        caption={link.title || link.caption}
                        link={link.url}
                      />
                    </Tag>
                  ))}
                {activity?.atts?.length >= 0 &&
                  activity.atts.map((file) => (
                    <Tag
                      style={{ borderRadius: "50px", margin: 1 }}
                      key={file.id}
                    >
                      <PaperClipOutlined style={{ margin: "0px 5px" }} />
                      <LinkItem
                        style={{ margin: "0px 1px" }}
                        caption={removeExtension(file.title || file.orig_name)}
                        file={file}
                      />
                    </Tag>
                  ))}
              </div>
            </div>
          )
        }
        case FeaturesTypes.COMPANY: {
          const content = renderTitleByType(activity, key)
          return (
            <>
              <Text
                className="cursor-pointer"
                data-id={activity.id}
                onClick={(e) => handleOpenActivityModal(e, key, activity)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {content.trim()
                    ? content
                    : `Empty content of ${firstLetterToUpperCase(key)}`}
                  <Component activity={activity} keys={key} title={title} />
                  <div className="edit-header">
                    <Icon
                      component={DiaryEditSvg}
                      style={{ marginLeft: "8px" }}
                      onClick={(e) => handleOpenActivityModal(e, key, activity)}
                    />
                  </div>
                </div>
              </Text>
            </>
          )
        }
        case FeaturesTypes.TODO: {
          return (
            <DailyTodoItem
              handleClick={handleOpenActivityModal}
              todo={activity}
              status={key}
              setPickerIsOpenToParent={() => {}}
              onCloseParentModal={() => {}}
              handleSaveTodo={() => {}}
              placement="timeline"
            />
          )
        }
        case FeaturesTypes.TODO_DONE: {
          return (
            <DailyTodoItem
              handleClick={handleOpenActivityModal}
              todo={activity}
              status={key}
              setPickerIsOpenToParent={() => {}}
              onCloseParentModal={() => {}}
              handleSaveTodo={() => {}}
              placement="timeline_done"
            />
          )
        }
        default: {
          const content = renderTitleByType(activity, key)
          return (
            <>
              <Text
                className="cursor-pointer"
                data-id={activity.id}
                onClick={(e) => handleOpenActivityModal(e, key, activity)}
              >
                <div className="edit-header">
                  <Icon
                    component={DiaryEditSvg}
                    style={{ marginLeft: "8px" }}
                    onClick={(e) => handleOpenActivityModal(e, key, activity)}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {content.trim()
                    ? content
                    : `Empty content of ${firstLetterToUpperCase(key)}`}
                  <Component activity={activity} key={key} title={title} />
                </div>
              </Text>
            </>
          )
        }
      }
    },

    [
      allColors,
      currentPendingFile.id,
      currentPendingFile.pending,
      date,
      handleDownloadFile,
      handleExpandNote,
      handleOpenActivityModal,
      isLarge,
      notesConfig,
    ]
  )
  const renderDiaryContent = useCallback(() => {
    return (
      <>
        <DiaryContent
          setDiaryContent={setDiaryContent}
          setDiaryHtml={setDiaryHtml}
          diaryHtml={diaryHtml}
          diaryContent={diaryContent}
        />
      </>
    )
  }, [diaryContent, diaryHtml, setDiaryContent, setDiaryHtml])
  return {
    allColors,
    date,
    listContentStyle,
    handleDownloadFile,
    handleExpandNote,
    renderContent,
    listStyle,
    handleOpenActivityModal,
    renderDiaryContent,
    showAddedDate,
    showAddedDateX,
    subType,
  }
}
