import {
  EDIT_NOTE_IN_COMPANY,
  SAVE_EDITED_COMPANY,
} from "../../action-types/companies"
import { request } from "../../../api/axios_helper"
import { COMPANY_API_URL, FeaturesTypes } from "../../../common/constants"
import { getSpecificJob, getSpecificContact } from "../../selectors"
import { isEmpty } from "lodash"
import { updateSpecificLead } from "../todos/create_todo"
import { editSearchPage } from "../search/get-search"
import { updateDiaryActivity } from "../diaries/edit-diary"
import { edit } from "../contacts/edit-contact"

export const saveEditedCompany = (payload) => {
  return {
    type: SAVE_EDITED_COMPANY,
    payload,
  }
}
export const editCompany = (data) => async (dispatch, getState) => {
  try {
    const lead = getSpecificJob(getState())
    const contact = getSpecificContact(getState())
    const response = await request().edit(
      `${COMPANY_API_URL}${data.id}/`,
      data,
      dispatch,
      "patch"
    )
    if (response?.status === 200) {
      dispatch(saveEditedCompany(response.data))
      dispatch(editSearchPage(response.data, FeaturesTypes.COMPANY))
      dispatch(
        updateDiaryActivity({
          data: response.data,
          activity_key: FeaturesTypes.COMPANY,
        })
      )
      if (!isEmpty(lead) && lead?.company?.id === response.data.id) {
        dispatch(
          updateSpecificLead({
            ...lead,
            company: response.data,
          })
        )
      }
      if (!isEmpty(contact) && contact?.company?.id === response.data.id) {
        dispatch(
          edit({
            ...contact,
            company: response.data,
          })
        )
      }
    }
    return response.data
  } catch (err) {
    throw err
  }
}

export const editNoteInCompany = (payload) => ({
  type: EDIT_NOTE_IN_COMPANY,
  payload,
})
