/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useRef, useState, useEffect } from "react"
import { Button, Drawer, Popconfirm } from "antd"
import { CloseOutlined, ExpandAltOutlined } from "@ant-design/icons"
import { ReactComponent as CollapseExpandIcon } from '../../assets/images/collapse-svgrepo-com (1).svg'
import { useSelector } from "react-redux"
import {
  getSpecificCommunication,
} from "../../store/selectors/communications/get-communications-list"
import { useAction } from "../../hooks/useAction"
import { removeCommunication } from "../../store/actions/communication/remove-communication"
import { updateCommunication } from "../../store/actions/communication/update-communication"
import { setSpecificCommunication } from "../../store/actions/communication/create-communication"
import { QuilEditor } from "../editor/quil-editor"
import Text from "antd/es/typography/Text"
import { DATE_FORMAT } from "../../common/config"
import moment from "moment"
import { debounce } from "lodash"
import Spinner from '../loaders/spinner'
import { useCommunicationSpecData } from "../../hooks/useSpecData"
import { useActivityItem } from "../../hooks/useActivityItem"


export const CommunicationDrawer = ({ onClose }) => {
  const specificCommunication = useSelector(getSpecificCommunication)
  const loading = useSelector(state => state.communications.pending)
  const setCommunication = useAction(setSpecificCommunication)
  const { job: specificJob, contact: specificContact, communicationDataId } = useCommunicationSpecData(specificCommunication)
  const actionRemove = useAction(removeCommunication)
  const updateCommunicationAc = useAction(updateCommunication)
  const [show, setShow] = useState(false)
  const [modalWidth, setModalWidth] = useState(600)
  const editorRef = useRef()
  const { handleClick } = useActivityItem()
  const handleRemoveCommunication = () => {
    actionRemove(specificCommunication.id)
    onClose(specificCommunication.id)
  }
  const onExpandClick = () => {
    setModalWidth(modalWidth === 600 ? "90vw" : 600)
    editorRef.current.focus()
  }
  const actionAfterTyping = (d) => {

    if (d.id) {
      updateCommunicationAc(d)
    }
  }


  const debouncedAction = useCallback(debounce(actionAfterTyping, 1000), [])

  const handleChangeNote = useCallback(
    (html_body, deltaObj, source, editor) => {
      if (editorRef?.current?.focus) {
        editorRef.current.focus()
      }
      setCommunication({
        ...specificCommunication,
        html_body,
        body_editor: editor.getContents(),
        body: editor.getText(),
      })
      if (source === 'user') {
        debouncedAction({
          ...specificCommunication,
          html_body,
          body_editor: editor.getContents(),
          body: editor.getText(),
        })
      }

    },

    [setCommunication, specificCommunication]
  )

  const ConvoInformation = () => (
    <Text style={{ flexShrink: 0 }}>
      {communicationDataId && <span style={{ margin: '0px 4px 0px 8px' }}> - </span>}
      Convo:
      {specificCommunication.comm_type === 1
        ? " Email "
        : specificCommunication.comm_type === 2
          ? " Phone "
          : specificCommunication.comm_type === 3
            ? " Meeting "
            : specificCommunication.comm_type === 4
              ? " LinkedIn "
              : " "}
      on {moment(specificCommunication.comm_dt).format(DATE_FORMAT)}
    </Text>
  )
  useEffect(() => {
    return () => {
      debouncedAction.cancel()
    }
  }, [debouncedAction])
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setShow(true), 1)
    return () => clearTimeout(timeout)
  }, [])

  const isNoExpanded = modalWidth === 600

  return (
    <Drawer
      className={`conversation-drawer savvyy-modal ${isNoExpanded ? "" : "big-convo"}`}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", paddingTop: 5 }}>
            <Text style={{
              textAlign: 'left',
            }}>
              {specificJob?.title && specificCommunication.job && (
                <div style={{ display: 'flex' }}>
                  <div style={{ ...(isNoExpanded ? { maxWidth: '14vw' } : {}) }}>
                    {specificJob?.title
                      && <span className="modal-click-link" onClick={() => handleClick(specificJob, 'job', specificJob)}>{specificJob.title}</span>
                    }
                    {specificJob?.company?.name && !specificContact?.first_name
                      && <>
                        <span>, </span>
                        <span
                          className="modal-click-link"
                          onClick={() => handleClick(specificJob.company, 'company', specificJob.company)}
                        >{specificJob?.company?.name}
                        </span>
                      </>
                    }
                  </div>
                  <ConvoInformation />
                </div>
              )}
              {specificContact?.first_name && specificCommunication.contact && (
                <div style={{ display: 'flex' }}>
                  <div style={isNoExpanded ? { maxWidth: '14vw' } : {}}>
                    {specificContact?.first_name
                      && <span className="modal-click-link" onClick={() => handleClick(specificContact, 'contact', specificContact)}>{specificContact.first_name}</span>
                    }
                    {specificContact?.company?.name
                      && <>
                        <span>, </span>
                        <span
                          className="modal-click-link"
                          onClick={() => handleClick(specificContact.company, 'company', specificContact.company)}
                        >
                          {specificContact?.company?.name}
                        </span>
                      </>
                    }
                  </div>

                  {!specificCommunication.job &&
                    <ConvoInformation />}
                </div>
              )}
            </Text>
          </div>
          <div style={{ display: "flex" }}>
            <div onClick={onExpandClick} className="expand-icon" style={{ padding: "2px 1px 2px 1px" }}>
              {modalWidth !== 600 ? <CollapseExpandIcon /> : <ExpandAltOutlined style={{ fontSize: 20 }} />}

            </div>
            <CloseOutlined className="expand-icon" style={{ padding: "2px 1px 2px 1px", fontSize: "20px" }} onClick={onClose} />
          </div>
        </div >

      }
      placement={"left"}
      width={modalWidth}
      open
      onClose={onClose}
      closeIcon={false}
    >
      <div className={modalWidth === 600 ? "" : "big-convo-quill"} style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "2%", right: "5%" }}>
          {loading && <Spinner />}
        </div>
        {show &&
          <QuilEditor
            is_diary
            focus
            editorRef={editorRef}
            data-element={"textarea"}
            content={specificCommunication?.body_editor?.ops ? specificCommunication?.body_editor : specificCommunication?.html_body || specificCommunication?.body}
            onChange={handleChangeNote}
          />
        }
      </div>




      <div className="conversation-drawer__actions df jc_sb">

        <Popconfirm
          placement="topRight"
          className="delete_file"
          title="Delete communication? There is no undo."
          onConfirm={handleRemoveCommunication}
          okText="Delete"
          cancelText="Cancel"
          zIndex={2000}
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      </div>
    </Drawer >
  )
}
