import React, { Fragment } from "react"
import { Modal, Row, Tabs } from "antd"
import { DiscardChanges, NoteEditor } from "../index"
import { CompanyDetails } from "../company/company_details"
import { CompanyTables } from "../company/company_tables"
import withMemo from "../../store/HOC/with_memo"
import { useCompanyModal } from "../../hooks/useCompanyModal"
import ModalCloseIcon from "./modal-close-icon"

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

const EditCompany = ({
  onClose,
  handleOpenCompanyEdit,
  isAdd,
  handleOpenCompanyDeleteModal,
}) => {
  const {
    handleCloseNoteModal,
    locations,
    setInputValue,
    setAddTagStep,
    handleChangeCompanyLocation,
    handleSearchLocation,
    specificCompany,
    handleClose,
    handleOpenContactModal,
    handleSubmit,
    handleSearchNames,
    data,
    confirmModal,
    setData,
    companiesNames,
    inputRef,
    addTagStep,
    contactsColumns,
    contacts,
    handleAddNewNote,
    setConfirmModal,
    handleChangeNote,
    show,
    jobs_is_pending,
    companyModalRef,
    handleChangeTab,
    leads,
    showError,
    setShowError,
    companyNameRef,
    tab,
    handleClick,
    handleOpenEditContactModal,
    handleClickSearchCompany,
    sybType,
    isSearchCompanyLoading,
    isLeads,
    handleAddNewLead
  } = useCompanyModal({
    isAdd,
    onClose,
  })
  const handleAddNote = () => { }

  const tabPaneItems = [
    {
      key: "1",
      label: "Company Info",
      children: (
        <div style={{ minHeight: "510px" }}>
          <Row style={{ height: "100%" }} ref={companyModalRef}>
            <CompanyDetails
              handleOpenContactModal={handleOpenContactModal}
              isAdd={isAdd}
              handleClose={onClose}
              handleClick={handleOpenEditContactModal}
              handleOpenCompanyDeleteModal={handleOpenCompanyDeleteModal}
              layout={layout}
              handleSubmit={handleSubmit}
              handleSearchNames={handleSearchNames}
              handleClickSearchCompany={handleClickSearchCompany}
              data={data}
              confirmModal={confirmModal}
              setData={setData}
              companiesNames={companiesNames}
              handleOpenCompanyEdit={handleOpenCompanyEdit}
              specificCompany={specificCompany}
              handleSearchLocation={handleSearchLocation}
              handleChangeCompanyLocation={handleChangeCompanyLocation}
              setAddTagStep={setAddTagStep}
              setInputValue={setInputValue}
              locations={locations}
              inputRef={inputRef}
              addTagStep={addTagStep}
              contactsColumns={contactsColumns}
              contacts={contacts}
              showError={showError}
              setShowError={setShowError}
              companyNameRef={companyNameRef}
              isSearchCompanyLoading={isSearchCompanyLoading}
            />
          </Row>
        </div>
      ),
    },
    {
      key: "2",
      label: "Details",
      children: (
        <div style={{ minHeight: "510px" }}>
          <Row style={{ height: "100%" }}>
            <CompanyTables
              isAdd={isAdd}
              handleOpenCompanyDeleteModal={handleOpenCompanyDeleteModal}
              handleSubmit={handleSubmit}
              handleAddNewNote={handleAddNewNote}
              jobs_is_pending={jobs_is_pending}
              leads={leads}
              specificCompany={specificCompany}
              handleClick={handleClick}
              sybType={sybType}
              isLeads={isLeads}
              handleAddNewLead={handleAddNewLead}
            />
          </Row>
        </div>
      ),
    },
  ]
  return (
    <Fragment>
      <Modal
        closeIcon={<ModalCloseIcon />}
        className="company-modal savvyy-modal"
        open
        footer={null}
        onCancel={handleClose}
        onClose={handleClose}
        width={1200}
      >
        <Tabs
          type="card"
          activeKey={tab}
          items={tabPaneItems}
          onChange={handleChangeTab}
        />
        {confirmModal && (
          <DiscardChanges
            confirmModal={confirmModal}
            handleCancelConfirmModal={() => setConfirmModal(false)}
            onSubmitConfirmModal={onClose}
          />
        )}
      </Modal>
      {show && (
        <NoteEditor
          visible={show}
          handleAddNote={handleAddNote}
          handleEditNote={handleChangeNote}
          onClose={handleCloseNoteModal}
        />
      )}
    </Fragment>
  )
}
export default withMemo(EditCompany)
