import React, { Fragment, useCallback, useEffect, useState } from "react"
import "../styles/company.css"
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  Row,
  Table,
  Typography,
  Badge,
  Space,
  Spin
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getCompaniesSelector, getTotalCompany } from "../store/selectors"
import {
  getCompaniesTh,
  setCompanyNotes,
  setSpecificCompany,
  resetCompanies
} from "../store/actions/companies/get-companies"
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { DeleteCompany } from "../components"
import { TagsList } from "../components/tags/TagsList"
import withMemo from "../store/HOC/with_memo"
import { useColors } from "../hooks/useColors"
import { useTags } from "../hooks/useTags"
import { calculateLastPosition } from "../common/helpers"
import CompanyIcon from "../assets/images/Companies.svg"
import { useActivityItem } from "../hooks/useActivityItem"
import { FeaturesTypes } from "../common/constants"
import { DATE_FORMAT } from "../common/config"
import moment from "moment"


const { Title, Text } = Typography

const Company = () => {
  const { allColors } = useColors()
  useTags()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const pending = useSelector((state) => state.company.pending)
  const companies = useSelector(getCompaniesSelector)
  const totalCount = useSelector(getTotalCompany)
  const dispatch = useDispatch()
  const { handleClick } = useActivityItem()
  const settings = {
    bottom: "bottomRight",
    hasData: true,
    pagination: false,
    showHeader: true,
    size: "small",
    tableLayout: "fixed",
    title: undefined,
    top: "none",
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "16.8%",
      sorter: (a, b) => a?.name
        ?.toLowerCase()
        .localeCompare(b?.name?.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "state",
      width: "16.8%",
      sorter: (a, b) => a?.state?.toLowerCase()
        .localeCompare(b?.state?.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contacts",
      dataIndex: "contact_count",
      key: "contacts",
      width: "6.4%",
      sorter: (a, b) => a?.contact_count - b?.contact_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Notes",
      dataIndex: "note_count",
      key: "attachments",
      width: "6.4%",
      sorter: (a, b) => a?.note_count - b?.note_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "16.8%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },
    {
      title: "Added",
      key: "create_dt",
      dataIndex: "create_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.create_dt) - new Date(a.create_dt)
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Updated",
      key: "last_edit_dt",
      dataIndex: "last_edit_dt",
      width: "9.4%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.last_edit_dt) - new Date(a.last_edit_dt)
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      width: "7%",
      render: (_, company) => {
        return (
          <Fragment>
            <EditOutlined
              style={{ marginRight: "1rem" }}
              onClick={(e) => {
                e.stopPropagation()
                handleOpenCompanyModal(company)
              }}
            />
            <DeleteOutlined
              data-company={company.id}
              onClick={handleOpenCompanyDeleteModal}
            />
          </Fragment>
        )
      },
    },
  ]
  const handleOpenCompanyModal = useCallback(
    (company) => {
      if (!company.id) {
        dispatch(setCompanyNotes([]))
        handleClick({ id: "createCompany" }, FeaturesTypes.COMPANY, {}, { isEdit: false, status: "add", isAdd: true, is_communication_edit: false })
        return
      } else {
        handleClick(company, FeaturesTypes.COMPANY, company)
      }

    },
    [dispatch, handleClick]
  )

  const handleOpenCompanyDeleteModal = useCallback(
    (e) => {
      e.stopPropagation()
      const companyId = e === +e ? e : Number(e.currentTarget?.dataset?.company)

      const company = companies.find((item) => item.id === companyId)
      if (company) {
        dispatch(setSpecificCompany(company))
      }
      setOpenDeleteModal(true)
    },
    [companies, dispatch]
  )
  const handleCloseDeleteModal = useCallback(() => {
    setOpenDeleteModal(false)
  }, [])
  useEffect(() => {
    dispatch(getCompaniesTh())
    return () => {
      dispatch(resetCompanies())
    }
    //eslint-disable-next-line
  }, [])
  const handleLoadMore = useCallback(() => {
    dispatch(getCompaniesTh())
  }, [dispatch])
  const handleScroll = useCallback(
    (e) => {
      if (companies.length < totalCount)
        return calculateLastPosition(e, handleLoadMore)
    },
    [companies.length, handleLoadMore, totalCount]
  )
  useEffect(() => {
    const tableContent = document.querySelector(".ant-table-body")
    if (tableContent) {
      tableContent.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (tableContent) {
        tableContent.removeEventListener("scroll", handleScroll)
      }
    }

  }, [handleScroll])

  return (
    <Row className="contact-container ">
      <Col
        span={24}
        style={{ display: "flex", padding: "0px 8px", marginBottom: "16px" }}
      >
        <Title className="contact-title" level={4}>
          Company
        </Title>
        <Space>
          <Text className="contact-subtitle">Total</Text>
          <Badge
            style={{ marginRight: "16px", backgroundColor: "#009EEF" }}
            showZero
            count={totalCount}
            overflowCount={1000}
          />
        </Space>
        <Button
          type="primary"
          size={"default"}
          onClick={handleOpenCompanyModal}
        >
          <PlusOutlined />
          New
        </Button>
      </Col>
      <Col style={{ position: "relative" }} span={24}>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              className="app-empty"
              style={{ opacity: pending ? 0.5 : 1, padding: "20px" }}
              image={<img src={CompanyIcon} alt="empty-company-icon" className="home-empty-image" />}
              description={
                <span style={{ fontSize: 16 }}>
                  “It’s the economy, ******”. Whether you are covering an industry or exploring potential employers, company research is essential.
                </span>
              }
            />
          )}
        >

          <Spin spinning={pending} style={{
            position: "absolute", zIndex: 1, left: "49%", top: '50%'
          }} />

          <Table
            {...settings}
            className={companies.length > 0 ? "" : "table-empty"}
            showHeader={companies.length > 0}
            rowKey={(row) => row.id}
            scroll={{ y: "75vh" }}
            pagination={false}
            columns={columns}
            dataSource={companies}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  handleOpenCompanyModal(record)
                },
              }
            }}
          />

        </ConfigProvider>
      </Col>
      {openDeleteModal && (
        <DeleteCompany
          handleClose={handleCloseDeleteModal}
          visible={openDeleteModal}
        />
      )}
    </Row>
  )
}

export default withMemo(Company)
